// Library imports
import React, { useEffect } from 'react';
import { Col, Container, Row, Card, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// Component imports
import PrimaryButton from '../BootstrapElements/PrimaryButton';
import { Loader } from '../Loader';
// Controller imports
import { getAllProducts } from '../../controllers/ProductController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// Style imports
import { HeroMainText, HeroWrapper } from '../HeroSection/styles';
import { PriceSubText } from './styles';
import { PRIMARY_COLOR } from '../../config/theme';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { setPackages } from '../../store/slices/registerSlice';
import { addLoader, removeLoader } from '../../store/slices/appSlice';

const PricingSection = () => {
    const { loaders } = useSelector(state => state.app);
    const { packages } = useSelector(state => state.register);
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            console.log('Get all products - Packages')
            try {
                dispatch(addLoader('packages'))
                let productsRes = await getAllProducts();
                dispatch(setPackages(productsRes));
            } catch (error) {
                console.log(`Error geting products. ${error}`);
            } finally {
                dispatch(removeLoader('packages'))
            }
        }
        fetchData();
    }, []);

    return (
        <HeroWrapper>
            <Container>
                <Row>
                    <Col style={{ textAlign: 'center' }} sm={12} md={12} lg={12} xl={12}>
                        <HeroMainText>Current Packages</HeroMainText>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center' }} sm={12} md={12} lg={12} xl={12}>
                        <PriceSubText>
                            Our All Access Package grants you unrestricted entry to a wealth of information, empowering you to make informed choices and maximize your potential for success. Join us today and unleash the power of our picks data and services.
                        </PriceSubText>
                    </Col>
                </Row>
                <Row style={{ marginTop: '15px', justifyContent: 'center' }}>
                    {loaders.find(loader => loader === 'packages') ?
                        <Loader loading={true} />
                        :
                        packages.map((product, i) => {
                            return (
                                <Col key={i} style={{ display: 'flex', justifyContent: 'center' }} sm={12} md={12} lg={6} xl={4}>
                                    <Card style={{ width: '100%', marginBottom: '15px' }} className="text-center">
                                        <Card.Header>{product.name}</Card.Header>
                                        <Card.Body>
                                            <Card.Title as="h1">{`$${product.price}`}</Card.Title>
                                            <Card.Text>
                                                {product.description}
                                            </Card.Text>
                                        </Card.Body>
                                        <ListGroup className="list-group-flush">
                                            <ListGroup.Item><FontAwesomeIcon color={PRIMARY_COLOR} icon={faCheckCircle} /> Get every play</ListGroup.Item>
                                            <ListGroup.Item><FontAwesomeIcon color={PRIMARY_COLOR} icon={faCheckCircle} /> Web/Mobile app access</ListGroup.Item>
                                            <ListGroup.Item><FontAwesomeIcon color={PRIMARY_COLOR} icon={faCheckCircle} /> 24/7 bet assistance</ListGroup.Item>
                                        </ListGroup>
                                        <Card.Body>
                                            <Link to={`/register?plan=${product.id}`}>
                                                <PrimaryButton
                                                    text={'Get started'}
                                                    size='lg'
                                                />
                                            </Link>
                                        </Card.Body>
                                        <Card.Footer className="text-muted">2 days ago</Card.Footer>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </HeroWrapper>
    )
}

export default PricingSection