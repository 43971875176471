// Library imports
import { styled } from "styled-components";
import { Card } from "react-bootstrap";

export const GameContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const GameCard = styled(Card)`
    margin-right: 2%;
    width: 30%;
    margin-bottom: 15px;
`