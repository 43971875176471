// Library imports
import React from 'react';
// Component imports
import HeroSection from './HeroSection';
import PrimaryButton from './BootstrapElements/PrimaryButton';

const FAQ = () => {
    return (
        <HeroSection
            variant='silver'
            topLine='What comes with your package?'
            mainText='FAQs'
            subText="Join us at Plainsman Sports as we embark on this exciting journey of challenging the status quo and redefining the sports betting landscape. Together, we can turn the odds in our favor and achieve new heights of profitability and enjoyment in the world of sports betting."
            imageSide='left'
            buttons={[
                <PrimaryButton
                    text='Our Mission'
                    size="lg"
                    onClick={() => window.location = '/mission'}
                />
            ]}
            faq={true}
        />
    )
}

export default FAQ
