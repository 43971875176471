// Library imports
import React, { useState } from 'react';
import { Card, Container, Button, Alert, Form, Col } from 'react-bootstrap';
// Component imports
import Header from '../components/Header';
import Footer from '../components/Footer';
// Controller imports
import { login } from '../controllers/UserController';
// Firebase imports
import firebase from 'firebase';
// CSS imports
import '../css/Login.css';

const Login = () => {
    const [form, setForm] = useState('login');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    return (
        <div style={{ backgroundColor: '#ededed' }} className='page-wrapper'>
            <Header currentPage={'register'} />
            <Container style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '25px 10px' }}>
                <Card style={{ backgroundColor: '#fdfdfd', padding: 15, maxWidth: '600px', width: '100%' }}>
                    <Card.Title style={{ display: 'flex', alignItems: 'end' }}>
                        {form === 'login' ? 'Log In' : 'Password Reset'}
                    </Card.Title>
                    <Card.Body>
                        <Alert
                            variant={message.includes('success') ? 'success' : 'danger'}
                            style={{ textAlign: 'center', fontSize: '14px' }}
                            className={`${!message ? 'hide' : ''}`}
                        >
                            {message}
                        </Alert>
                        <Form onSubmit={async (e) => {
                            e.preventDefault();
                            try {
                                setLoading(true);
                                if (form === 'login') {
                                    await login(email, password);
                                }
                                if (form === 'forgot-password') {
                                    await firebase.auth().sendPasswordResetEmail(email);
                                    setMessage('Password reset email set successfully.');
                                }
                            } catch (error) {
                                setMessage(error.message ? error.message : error);
                            } finally {
                                setLoading(false);
                            }
                        }}>
                            <Form.Group as={Col} controlId="email" style={{ marginBottom: '15px' }}>
                                <Form.Label>Email Address*</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    value={email}
                                    style={{ textTransform: 'lowercase' }}
                                    autoCapitalize='none'
                                    onKeyDown={(e) => {
                                        if (e.key === " ") {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={e => setEmail(e.target.value)}

                                />
                            </Form.Group>
                            {form !== 'forgot-password' ?
                                <Form.Group as={Col} controlId="password" style={{ marginBottom: '15px' }}>
                                    <Form.Label>Password*</Form.Label>
                                    <Form.Control
                                        required
                                        value={password}
                                        type="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>
                                :
                                <></>
                            }
                            <Button
                                variant="primary"
                                type="submit"
                                style={{ width: '100%', marginTop: '15px' }}
                            >
                                {form === 'forgot-password' ?
                                    'Recover Password'
                                    :
                                    'Enter'
                                }
                            </Button>
                            {form === 'login' ?
                                <div className="forgot-wrapper">
                                    <button
                                        className='forgot-line'
                                        id='forgot-password'
                                        onClick={() => setForm('forgot-password')}
                                    >
                                        Forgot Password?
                                    </button>
                                </div>
                                :
                                <></>
                            }
                            {form !== 'login' ?
                                <div className="forgot-wrapper">
                                    <button onClick={() => setForm('login')}>
                                        Return to Login
                                    </button>
                                </div>
                                :
                                <></>
                            }
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
            <Footer />
        </div>
    )
}

export default Login