// Library imports
import React from 'react';
import { Button } from 'react-bootstrap';
// Config imports
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../config/theme';

const PrimaryButton = (props) => {
  const { text } = props;
  return (
    <Button
      style={{ backgroundColor: PRIMARY_COLOR, border: `1px solid ${SECONDARY_COLOR}` }}
      {...props}
    >
      {text}
    </Button>
  )
}

export default PrimaryButton