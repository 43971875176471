// Library imports
import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Alert } from 'react-bootstrap';
import queryString from 'query-string';
// Component imports
import Header from '../components/Header';
import Footer from '../components/Footer';
import RegisterForm from '../components/RegisterForm';
import Payment from '../components/Payment';
// Redux imports
import { updateData } from '../store/slices/registerSlice';
import { useDispatch, useSelector } from 'react-redux';

const Register = () => {
    const { packages } = useSelector(state => state.register);
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [stage, setStage] = useState('info');

    useEffect(() => {
        let params = queryString.parse(window.location.href.split('?')[1]);
        const product = packages.find(product => product.id === params.plan)
        dispatch(updateData({ label: 'plan', value: product ? { ...product } : null }));
    }, []);

    return (
        <div style={{ backgroundColor: '#ededed' }} className='page-wrapper'>
            <Header currentPage={'register'} />
            <Container style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '25px 10px' }}>
                <Card style={{ backgroundColor: '#fdfdfd', padding: 15, maxWidth: '990px', width: '100%' }}>
                    <Card.Title style={{ display: 'flex', alignItems: 'end' }}>
                        {stage === 'info' ? 'Account Information' : stage === 'pay' ? 'Payment Details' : 'Complete'}
                        {stage === 'info' ?
                            <Button
                                variant='link'
                                style={{ padding: 0, marginLeft: 15, fontSize: 14, fontWeight: 400 }}
                                onClick={() => window.location.href = '/packages'}
                            >
                                Cancel
                            </Button>
                            :
                            stage === 'pay' ?
                                <Button
                                    variant='link'
                                    style={{ padding: 0, marginLeft: 15, fontSize: 14, fontWeight: 400 }}
                                    onClick={() => {
                                        setError('');
                                    }}
                                >
                                    Back
                                </Button>
                                :
                                <></>

                        }
                    </Card.Title>
                    <Card.Body>
                        <Alert
                            variant='danger'
                            style={{ textAlign: 'center' }}
                            className={`${!error ? 'hide' : ''}`}
                        >
                            {error}
                        </Alert>
                        {stage === 'info' ?
                            <RegisterForm
                                setError={setError}
                                setStage={setStage}
                            />
                            :
                            stage === 'pay' ?
                                <Payment
                                    setError={setError}
                                />
                                :
                                <></>
                        }
                    </Card.Body>
                </Card>
            </Container>
            <Footer />
        </div>
    )
}

export default Register