// Constant imports
import { HOST_URL } from '../config/constants';
// Controller imports
import { getUserToken } from "./UserController";

export const getAllGameData = async sport => {
	const token = await getUserToken();
	console.log(`${HOST_URL}/api/games?sport=${sport}`)
	return fetch(`${HOST_URL}/api/games?sport=${sport}`, {
		method: "GET",
		headers: {
			authorization: `Bearer ${token}`
		}
	}).then(async response => {
		const json = await response.json();
		if (json.error) throw new Error(json.error);
		return json;
	});
};

export const getAllTeamData = async sport => {
	sport = String(sport).toLowerCase();
	return fetch(`${HOST_URL}/api/teams?sport=${sport}`, {
		method: "GET",
	}).then(async response => {
		const json = await response.json();
		if (json.error) throw new Error(json.error);
		return json;
	});
};

export const getGameTeamData = async (sport, game) => {
	sport = String(sport).toLowerCase();
	return getAllTeamData(sport).then(allTeamInfo => {
		const awayTeamData = allTeamInfo.find(team => team.teamId === game.awayTeam.teamId);
		const homeTeamData = allTeamInfo.find(team => team.teamId === game.homeTeam.teamId);
		if (awayTeamData === undefined) {
			console.log("--- " + game.awayTeam.teamName + " team data not found ---");
		}
		if (homeTeamData === undefined) {
			console.log("--- " + game.homeTeam.teamName + " team data not found ---");
		}
		return { awayTeamData, homeTeamData };
	});
};

export const keyFactorList = async sport => {
	const res = await fetch(`${HOST_URL}/api/keyfactors?sport=${sport}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		},
	});
	const json = await res.json();
	if (json.error) throw new Error(json.error);
	return json;
}