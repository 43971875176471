// Library imports
import React from "react";
// Style imports
import { DetailSection, SectionSubTitle, SectionTitle } from '../styles';
import moment from "moment";
import { Alert } from "react-bootstrap";

const History = ({ game, sport }) => {
    return (
        <DetailSection>
            <SectionTitle>
                Matchup History
            </SectionTitle>
            {!game.history ?
                <></>
                :
                <SectionSubTitle>
                    {moment(game.history.dateTime).format('MMMM Do YYYY')}
                </SectionSubTitle>
            }
            <Alert variant='primary' className='text-center mt-2'>No matchup history reported.</Alert>
        </DetailSection>
    )
}

export default History