// Library imports
import React from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
// Component imports
import PrimaryButton from '../BootstrapElements/PrimaryButton';
// Style imports
import { HeroWrapper } from '../HeroSection/styles';
import { QuoteAuthor, QuoteContent, Title } from './styles';
import { PRIMARY_COLOR } from '../../config/theme';

const testimonials = [
    {
        author: 'Chad Adams, LAS VEGAS',
        content: "Herb and his team helped elevated my sports betting game and taught me bankroll management. I've turned the tables on the sportsbooks. Trust me, teaming up with them is a game-changer!"
    },
    {
        author: 'Russell, AL',
        content: "Herb helped me understand how to bet. Their picks have been spot-on, helping me consistently beat the sportsbook. Joining their team was the best decision I made for my betting success!"
    },
    {
        author: 'Mike "POLO" Trammel, GA',
        content: 'Plainsman Sports transformed my sports betting journey. Their expert picks and insightful analysis have helped me consistently beat the sportsbook. Joining their team was the best decision I made!'
    },
];

const Testimonials = ({ variant = 'light' }) => {
    return (
        <HeroWrapper variant={variant}>
            <Container>
                <Title>
                    Testimonials
                </Title>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    {testimonials.map((item, i) => {
                        return (
                            <Col key={i} sm={12} md={12} lg={6} xl={6}>
                                <Card style={{ marginBottom: '25px' }}>
                                    <Card.Header>
                                        <FontAwesomeIcon color={PRIMARY_COLOR} icon={faStar} />
                                        <FontAwesomeIcon color={PRIMARY_COLOR} icon={faStar} />
                                        <FontAwesomeIcon color={PRIMARY_COLOR} icon={faStar} />
                                        <FontAwesomeIcon color={PRIMARY_COLOR} icon={faStar} />
                                        <FontAwesomeIcon color={PRIMARY_COLOR} icon={faStar} />
                                    </Card.Header>
                                    <Card.Body>
                                        <QuoteContent>
                                            {item.content}
                                        </QuoteContent>
                                        <QuoteAuthor>
                                            {item.author}
                                        </QuoteAuthor>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
                <Row>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <PrimaryButton
                            text='Package Options'
                            size="lg"
                            onClick={() => window.location = '/packages'}
                        />
                    </Col>
                </Row>
            </Container>
        </HeroWrapper>
    );

}

export default Testimonials