// Library imports
import uuid from 'react-native-uuid';
import moment from 'moment';
// Firebase imports
import firebase from 'firebase';

export const getPicks = async (productId = undefined) => {
    var res;
    if (!productId) {
        res = await firebase.firestore().collection('picks').get();
    } else {
        res = await firebase.firestore().collection("picks").where("products", "array-contains", productId).get();
    }
    const arr = [];
    res.forEach(doc => arr.push(doc.data()));
    arr.sort((a, b) => a.dateTime < b.dateTime ? 1 : -1);
    return arr;
}

export const addPick = async (data) => {
    const id = uuid.v4();
    data.id = id;
    data.dateTime = moment().format();
    return await firebase.firestore().collection('picks').doc(data.id).set(data);
}

export const editPick = async (id, field, value) => {
    await firebase.firestore().collection('picks').doc(id).set({
        [field]: value
    }, { merge: true })
}

export const deletePick = async (id) => {
    await firebase.firestore().collection('picks').doc(id).delete();
} 