// Library imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// Redux imports
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import rootReducer from './store/rootReducer';
// Component imports
import Home from './static_pages/Home';
import Packages from './static_pages/Packages';
import Contact from './static_pages/Contact';
import Register from './static_pages/Register';
import App from './app_pages/App';
import Login from './static_pages/Login';
import MyStory from './static_pages/MyStory';
import Mission from './static_pages/Mission';
import Blog from './static_pages/Blog';
// CSS imports
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css';
// Firebase init
import './Firebase';

export const store = configureStore({
  reducer: rootReducer,
})

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/my-story",
    element: <MyStory />,
  },
  {
    path: "/mission",
    element: <Mission />,
  },
  {
    path: "/packages",
    element: <Packages />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/register",
    element: <Register />
  },
  ,
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/app",
    element: <App />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

