import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {},
    packages: []
}

const registerSlice = createSlice({
    name: 'register',
    initialState: initialState,
    reducers: {
        updateData: (state, { payload }) => {
            state.data = { ...state.data, [payload.label]: payload.value }
        },
        setPackages: (state, { payload }) => {
            state.packages = [...payload]
        }
    }
})

export const { updateData, setPackages } = registerSlice.actions;

export default registerSlice.reducer;