// Library imports
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// Component imports
import Header from '../components/Header';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
// Style imports
import { HeroMainText, HeroSubText, HeroTopLine } from '../components/HeroSection/styles';
import { HeroWrapper } from '../components/HeroSection/styles';
import PrimaryButton from '../components/BootstrapElements/PrimaryButton';

const MyStory = () => {
    return (
        <div className='page-wrapper'>
            <Header
                currentPage={'home'}
            />
            <HeroWrapper>
                <Container>
                    <PrimaryButton
                        text='Return'
                        style={{ marginTop: '-50px', }}
                        size='sm'
                        onClick={() => window.location = '/home'}
                    />
                    <HeroTopLine>
                        Who Leads Plainsman Sports?
                    </HeroTopLine>
                    <HeroMainText>
                        Hi! I&#39;m Herb West
                    </HeroMainText>
                    <HeroSubText>
                        Herb West is a name that strikes fear into the hearts of any sportsbook or bookie alike that
                        has faced off against him. Known as a professional sports bettor and poker player, Herb&#39;s
                        journey to success was far from easy. He learned the ropes the hard way after experiencing
                        countless losses at the hands of the sportsbooks, paying his local bookies way too often.
                    </HeroSubText>
                    <HeroSubText>
                        Hi, my name is Herb West and this is my story. Having been in the shoes of struggling
                        sports bettors, I understand the pain points and frustrations that come with constantly
                        falling short against the sportsbooks&#39; relentless advantage. Determined to turn the tables, I
                        embarked on a quest to educate myself, analyze data, and develop winning strategies.
                    </HeroSubText>
                    <HeroSubText>
                        It was through years of dedication, perseverance, and a fair share of trial and error that I
                        finally cracked the code. I not only learned how to win consistently but also discovered the
                        power of teamwork. I realized that going up against the sportsbooks required more than
                        just individual efforts; it demanded a unified team of like-minded individuals who shared
                        the same goal of conquering the books.
                    </HeroSubText>
                    <HeroSubText>
                        With an unwavering spirit and armed with a team of seasoned experts, my colleagues and I
                        have consistently dominated the sportsbook year after year. I have developed a proven
                        track record of success, leveraging our team’s deep insights, analytical skills, and cutting-
                        edge data crunching techniques to identify sharp plays that consistently outsmart the
                        sportsbooks.
                    </HeroSubText>
                    <HeroSubText>
                        My mission now is to share my hard-earned knowledge, resources, and winning strategies
                        with other sports bettors who have suffered at the hands of the sportsbook. I know what it
                        feels like to be robbed of hard-earned money, and I’m determined to empower others to
                        break free from that cycle.
                    </HeroSubText>
                    <HeroSubText>
                        My team is well-equipped to guide sports bettors on their journey to success, offering
                        expert picks, personalized guidance, and a supportive community that understands the
                        challenges and triumphs of the betting world. However, I have to issue a warning to those
                        who decide to join my winning team – once you start playing my picks, the sportsbooks will
                        take notice and do everything they can to limit your success. They fear winners, and they
                        will limit or cut you off from betting with them.
                    </HeroSubText>
                    <HeroSubText>
                        To overcome this obstacle, I advise all my followers to have multiple local or global
                        accounts ready to go. It is a testament to our team&#39;s success that once the books realize
                        your winning streak is no fluke, but the result of years of insightful analysis, they will try to
                        prevent you from betting with them. But fear not, because with my team and I on your
                        side, you&#39;ll be ready to conquer any setback and continue your winning journey.
                    </HeroSubText>
                    <HeroSubText>
                        If you&#39;re ready to turn the tables on the sportsbooks, become a winning bettor, and add an
                        additional stream of income to your bottom line, then I’m here to guide you. I invite you to
                        embark on a new journey, armed with my proven strategies and insights, and join the
                        ranks of successful sports bettors who have overcome the odds and crushed the markets.
                    </HeroSubText>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '15px' }}>
                            <PrimaryButton
                                text='Package Options'
                                size="lg"
                                onClick={() => window.location = '/packages'}
                            />
                        </Col>
                    </Row>
                </Container>
            </HeroWrapper>
            <Testimonials variant='silver' />
            <Footer />
        </div >
    )
}

export default MyStory;