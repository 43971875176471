// Library imports
import React, { useEffect, useState } from 'react';
import { Container, Image } from 'react-bootstrap';
// Component imports
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PostCard from './PostCard';
// Style components
import { BlogContainer, BlogHeader } from './styles';
import { HeroMainText, HeroWrapper } from '../../components/HeroSection/styles';
import { useDispatch, useSelector } from 'react-redux';
import { addLoader, removeLoader } from '../../store/slices/appSlice';
import { Loader } from '../../components/Loader';

const Blog = () => {
    // Redux
    const { loaders } = useSelector(state => state.app)
    const dispatch = useDispatch();
    // Local State
    const [authorData, setAuthorData] = useState({});
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(addLoader('blog'))
                const res = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@herbwest');
                const data = await res.json();
                setAuthorData(data.feed)
                const posts = data.items;
                setBlogs(posts);
            } catch (error) {
                console.log(`Error fetching blogs\n${error}`)
            } finally {
                dispatch(removeLoader('blog'))
            }
        }

        fetchData();
    }, []);

    return (
        <div className='page-wrapper'>
            <Header
                currentPage={'blog'}
            />
            <HeroWrapper style={{ padding: '25px 0px', flex: 1 }}>
                <Container style={{ flex: 1 }}>
                    {loaders.find(loader => loader === 'blog') ?
                        <Loader style={{ marginTop: '5%' }} />
                        :
                        <>
                            <BlogHeader>
                                <Image src={authorData.image} height='125px' roundedCircle />
                                <div style={{ marginLeft: '15px', marginTop: '15px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
                                    <HeroMainText onClick={() => window.location = authorData.url} style={{ fontSize: '30px' }}>Herb West</HeroMainText>
                                    <HeroMainText style={{ fontSize: '18px', color: '#666', fontWeight: 300, marginTop: '-15px' }}>{authorData.title}</HeroMainText>
                                </div>
                            </BlogHeader>
                            <BlogContainer>
                                {blogs.map(blog => {
                                    return <PostCard {...blog} key={blog.title} />
                                })}
                            </BlogContainer>
                        </>
                    }
                </Container>
            </HeroWrapper >
            <Footer />
        </div >
    )
}

export default Blog;