import React from 'react';
import { Nav } from 'react-bootstrap';

const SportsNav = ({ click, all = true, defaultValue = 'all', demo = false }) => {
    return (
        <Nav style={{ margin: '1rem 0px' }} variant="pills" defaultActiveKey={defaultValue}>
            {all ?
                <Nav.Item >
                    <Nav.Link
                        style={{ fontSize: '16px' }}
                        id='all'
                        eventKey={'all'}
                        onClick={e => click(e.target.id)}
                    >
                        All
                    </Nav.Link>
                </Nav.Item >
                :
                <></>
            }
            <Nav.Item>
                <Nav.Link
                    style={{ fontSize: '16px' }}
                    id='mlb'
                    eventKey={'mlb'}
                    onClick={e => click(e.target.id)}
                >
                    MLB
                </Nav.Link>
            </Nav.Item>
            {!demo ?
                <Nav.Item>
                    <Nav.Link
                        style={{ fontSize: '16px' }}
                        id='ncaa-baseball'
                        eventKey={'ncaa-baseball'}
                        onClick={e => click(e.target.id)}
                    >
                        NCAA Baseball
                    </Nav.Link>
                </Nav.Item>
                :
                <></>
            }
            <Nav.Item>
                <Nav.Link
                    style={{ fontSize: '16px' }}
                    id='nfl'
                    eventKey={'nfl'}
                    onClick={e => click(e.target.id)}
                >
                    NFL
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    style={{ fontSize: '16px' }}
                    id='cfb'
                    eventKey={'cfb'}
                    onClick={e => click(e.target.id)}
                    disabled={demo}
                >
                    CFB
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    style={{ fontSize: '16px' }}
                    id='nba'
                    eventKey={'nba'}
                    onClick={e => click(e.target.id)}
                >
                    NBA
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    style={{ fontSize: '16px' }}
                    id='cbb'
                    eventKey={'cbb'}
                    onClick={e => click(e.target.id)}
                >
                    CBB
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    style={{ fontSize: '16px' }}
                    id='nhl'
                    eventKey={'nhl'}
                    onClick={e => click(e.target.id)}
                >
                    NHL
                </Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default SportsNav
