import { styled } from "styled-components";

export const Title = styled.h1`
    text-align: center;
    font-size: 54px;
    font-weight: 800;
    line-height: 1.1;
    margin-bottom: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
`

export const QuoteContent = styled.p`
    font-size: 20px;
    line-height: 1.75rem;
    margin-bottom: 1rem;
    margin-top: 0;
    color: #333;
`

export const QuoteAuthor = styled.h3`
    font-size: 18px;
    font-weight: 400;
    color: #6c757d!important;
`