// Library imports
import moment from 'moment';
// Firebase imports
import firebase from 'firebase';

export const login = async (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password)
        .then((user) => {
            if (user) {
                window.location = "/app";
            }
        });
};

export const getUsers = async () => {
    const res = await firebase.firestore().collection('users').get();
    const arr = [];
    res.forEach(doc => arr.push(doc.data()));
    return arr;
}


export const getUserToken = async () => {
    return await firebase.auth().currentUser.getIdToken();
};

export const addPackage = async (uid, product) => {
    product.startDate = moment().format();
    product.endDate = moment(product.timespan.hardDate).format();
    delete product.timespan;
    await firebase.firestore().collection('users').doc(uid).set({
        products: firebase.firestore.FieldValue.arrayUnion(product)
    }, { merge: true })
}

export const deletePackage = async (user, product) => {
    const removeArr = user.products.filter(userProduct => {
        if (userProduct.id === product.id && userProduct.endDate === product.endDate) {
            return false;
        }
        return true;
    })
    await firebase.firestore().collection('users').doc(user.uid).set({
        products: removeArr
    }, { merge: true })
}