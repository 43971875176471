// Component imports
import React, { useState } from 'react';
import validator from 'validator';
import { Form, Col, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
// Firebase imports
import firebase from 'firebase';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { updateData } from '../../store/slices/registerSlice';

const RegisterForm = ({ setError, setStage }) => {
    const { data } = useSelector(state => state.register);
    const dispatch = useDispatch();

    return (
        <Form onSubmit={async (e) => {
            e.preventDefault();
            if (!data) return setError('Please fill out all required fields. [All Data].');
            if (!data.email) return setError('Please fill out all required fields. [Email].');
            try {
                const emailExist = await firebase.auth().fetchSignInMethodsForEmail(data.email);
                if (!data.password) throw new Error('Please fill out all required fields. [Password].');
                if (!data.vpassword) throw new Error('Please fill out all required fields. [Verify Password].');
                if (!data.displayName) throw new Error('Please fill out all required fields. [Display Name].');
                if (data.displayName.length > 14) throw new Error('Display name must be shorter than 15 characters and include no spaces.');
                if (data.password !== data.vpassword) throw new Error('Passwords do not match.  Please try again');
                if (!validator.isLength(data.password, { min: 5, max: 100 })) throw new Error('Password must be between 5 and 100 characters.');
                if (emailExist.length !== 0) throw new Error('That email already exists.  Please use a different email.');
                if (!validator.isEmail(data.email)) throw new Error('Please enter a valid email.');
                setStage('pay')
                setError('');
            } catch (error) {
                setError(error.message);
                console.log(`Register error\n${error}`)
            }
        }}>
            <Form.Group as={Col} controlId="email" sm={12}>
                <Form.Label>Email Address*</Form.Label>
                <Form.Control
                    required
                    type="email"
                    defaultValue={data.email ? data.email : ''}
                    style={{ textTransform: 'lowercase' }}
                    autoCapitalize='none'
                    onKeyDown={(e) => {
                        if (e.key === " ") {
                            e.preventDefault();
                        }
                    }}
                    onChange={e => {
                        const value = e.currentTarget.value.toLowerCase();
                        dispatch(updateData({ label: e.currentTarget.id, value: value }))
                    }}
                />
            </Form.Group>
            <Form.Group style={{ marginTop: '15px' }} as={Col} controlId="displayName" sm={12}>
                <Form.Label>Display Name*</Form.Label>
                <Form.Control
                    required
                    defaultValue={data.displayName ? data.displayName : ''}
                    onKeyDown={(e) => {
                        if (e.key === " ") {
                            e.preventDefault();
                        }
                    }}
                    onChange={e => {
                        dispatch(updateData({ label: e.currentTarget.id, value: e.currentTarget.value }))
                    }}
                />
                <Form.Text className="text-muted">Must be between 5 and 15 characters & no spaces.</Form.Text>
            </Form.Group>
            <Form.Group style={{ marginTop: '15px' }} as={Col} controlId="password" sm={12}>
                <Form.Label>Password*</Form.Label>
                <Form.Control
                    required
                    defaultValue={data.password ? data.password : ''}
                    type="password"
                    onChange={e => {
                        dispatch(updateData({ label: e.currentTarget.id, value: e.currentTarget.value }))
                    }}
                />
                <Form.Text className="text-muted">Password must be between 5 and 100 characters.</Form.Text>
            </Form.Group>
            <Form.Group style={{ marginTop: '15px' }} as={Col} controlId="vpassword" sm={12}>
                <Form.Label>Verify Password*</Form.Label>
                <Form.Control
                    required
                    defaultValue={data.vpassword ? data.vpassword : ''}
                    type="password"
                    onChange={e => {
                        dispatch(updateData({ label: e.currentTarget.id, value: e.currentTarget.value }))
                    }}
                />
            </Form.Group>
            <Button
                variant="primary"
                type="submit"
                style={{ marginTop: '25px', marginLeft: 'auto', display: 'flex' }}
            >
                Continue <FontAwesomeIcon style={{ marginLeft: 7, marginTop: 5 }} icon={faArrowCircleRight} />
            </Button>
        </Form>
    );
}

export default RegisterForm