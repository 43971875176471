// Library imports
import React from 'react';
import { Card } from 'react-bootstrap';

const generateDesc = (text) => {
    return text.replace(/<(.|\n)*?>/g, '').replace('Picture by: Garret Schappacher', '').slice(0, 191) + '...';
}

const PostCard = (props) => {
    const monthShortname = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const splitDate = props.pubDate.split(" ");
    const date = splitDate[0];
    const splitMonth = date.split("-");
    const finalDate =
        monthShortname[Number(splitMonth[1] - 1)] +
        " " +
        splitMonth[2] +
        "," +
        " " +
        splitMonth[0];
    const d = new Date();
    return (
        <Card style={{ width: '315px', marginRight: '15px' }}>
            <Card.Img style={{ width: '100%', height: '275px' }} variant="top" src={props.thumbnail} />
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{props.author} - {finalDate}</Card.Subtitle>
                <Card.Text>
                    {generateDesc(props.content)}
                </Card.Text>
                <Card.Link target='_blank' href={props.link}>Read Blog</Card.Link>
            </Card.Body>
        </Card>
    )
}

export default PostCard