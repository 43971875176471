// Firebase imports
import firebase from 'firebase';

export const getAllProducts = async () => {
    const res = await firebase.firestore().collection('packages').get();
    const arr = [];
    res.forEach(doc => arr.push(doc.data()));
    return arr;
}

export const createProduct = async (productData) => {
    return await firebase.firestore().collection('packages').doc(productData.id).set(productData);
}

export const deleteProduct = async (id) => {
    return await firebase.firestore().collection('packages').doc(id).delete();
}