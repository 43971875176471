// Library imports
import React, { useState, useEffect } from 'react';
import { Alert, Button, Nav } from 'react-bootstrap';
import moment from 'moment';
// Component imports
import Table from '../../Table';
import Dropdown from '../../BootstrapElements/Dropdown';
import { Loader } from '../../Loader';
// Controller imports
import { editPick, getPicks } from '../../../controllers/PickController';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { addLoader, removeLoader } from '../../../store/slices/appSlice';
// Util imports
import { addPlusSign, calcRecord } from '../../../utils/Global';
// Style imports
import { TableHeader, TableSubHeader } from './styles';

const Picks = () => {
    // Redux
    const { user, loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [picks, setPicks] = useState([]);
    const [tab, setTab] = useState('pending');
    const [historyPicks, setHistoryPicks] = useState([]);

    const reloadPicks = async () => {
        try {
            dispatch(addLoader('picks'))
            const pickRes = await getPicks();
            setPicks(pickRes);
        } catch (error) {
            console.log(`Error getting picks. ${error}`);
        } finally {
            dispatch(removeLoader('picks'))
        }
    }

    useEffect(() => {
        reloadPicks();
    }, []);

    const generateRows = (picks, completed = false) => {
        const rows = picks.map((pick, i) => {
            var tableClass = '';
            var textClass = '';
            if (completed) {
                if (pick.status === 'win') {
                    tableClass = 'table-success';
                    textClass = 'text-success';
                }
                if (pick.status === 'loss') {
                    tableClass = 'table-danger';
                    textClass = 'text-danger';
                }
            }
            return (
                <tr key={i} className={tableClass}>
                    <td>
                        {pick.type}
                    </td>
                    <td>
                        {pick.plays.map((play, i) => {
                            return (
                                <div key={i} style={{ marginTop: i > 0 ? '10px' : '0px' }}>
                                    <span className={textClass} style={{ display: 'block', fontSize: '12px', color: '#666', lineHeight: '10px' }}>
                                        {moment(pick.time).format('MM/DD h:mm a')}
                                    </span>
                                    <span className={textClass} style={{ fontWeight: 'bold' }}>
                                        {play.name}
                                    </span>
                                    <span className={textClass} style={{ fontSize: 14, marginLeft: '5px' }}>
                                        ({play.sport})
                                    </span>
                                </div>
                            )
                        })}
                    </td>
                    <td>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {pick.plays.map((play, i) => {
                                return (
                                    <span className={textClass} style={{ marginTop: i > 0 ? '15px' : '0px' }} key={i}>
                                        {play.type !== 'total' ? play.odds === 0 ? 'PK' : addPlusSign(play.odds) : play.odds}
                                    </span>
                                )
                            })}
                        </div>
                    </td>
                    <td>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {pick.plays.map((play, i) => {
                                return (
                                    <span className={textClass} style={{ marginTop: i > 0 ? '15px' : '0px' }} key={i}>
                                        {play.type === 'moneyline' ? 'ML' : addPlusSign(play.juice)}
                                    </span>
                                )
                            })}
                        </div>
                    </td>
                    <td className={textClass}>
                        {pick.units}
                    </td>
                    <td className={textClass}>
                        {completed || !user.admin ?
                            pick.status
                            :
                            <Dropdown
                                drop='end'
                                variant={'light'}
                                dropdownStyle={{ border: '1px solid #ddd' }}
                                value={'pending'}
                                setValue={async (selectedKey) => {
                                    await editPick(pick.id, 'status', selectedKey);
                                    reloadPicks();
                                }}
                                menuItems={['pending', 'win', 'loss', 'push'].map(type => type)}
                            />
                        }
                    </td>
                </tr>
            );
        })
        return rows;
    }

    if (loaders.find(loader => loader === 'picks')) {
        return <Loader loading={true} />
    }

    if (historyPicks.length) {
        return (
            <>
                <TableHeader>
                    Play History
                    <Button
                        style={{ marginLeft: 'auto' }}
                        onClick={() => setHistoryPicks([])}
                    >
                        Back
                    </Button>
                </TableHeader>
                <TableSubHeader style={{ paddingBottom: '15px', borderBottom: '1px solid #ddd', paddingTop: '15px', borderTop: '1px solid #ddd' }}>
                    Record: {calcRecord(historyPicks)}
                </TableSubHeader>
                {!historyPicks.length ?
                    <Alert variant='info'>
                        No completed plays at this time.  Check back soon.
                    </Alert>
                    :
                    <Table
                        headers={['Type', 'Play', 'Odds', 'Juice', 'Units', 'Grade']}
                        rows={generateRows(historyPicks, true)}
                        striped={false}
                    />
                }
            </>
        )
    }

    if (user.admin) {
        return (
            <>
                <TableHeader>
                    Pending Plays
                </TableHeader>
                {!picks.filter(pick => pick.status === 'pending').length ?
                    <Alert variant='info'>
                        No pending plays at this time.  Check back soon.
                    </Alert>
                    :
                    <Table
                        headers={['Play', 'Odds', 'Juice', 'Units', 'Grade']}
                        rows={generateRows(picks.filter(pick => pick.status === 'pending'), false)}
                    />
                }
                <TableHeader>
                    Completed Plays
                    <Button
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                            setHistoryPicks(picks.filter(pick => pick.status !== 'pending'))
                        }}
                    >
                        View All
                    </Button>
                </TableHeader>
                {!picks.filter(pick => pick.status !== 'pending').length ?
                    <Alert variant='info'>
                        No completed plays at this time.  Check back soon.
                    </Alert>
                    :
                    <Table
                        headers={['Type', 'Play', 'Odds', 'Juice', 'Units', 'Grade']}
                        rows={generateRows(picks.filter(pick => pick.status !== 'pending').slice(-5), true)}
                        striped={false}
                    />
                }
            </>
        )
    }

    if (!user.products?.filter(product => moment(product.endDate).isAfter(moment())).length) {
        return (
            <Alert variant='info'>
                You have no active products.
            </Alert>
        )
    }
    return (
        <>
            <Nav style={{ marginBottom: '15px' }} variant="pills" defaultActiveKey={tab}>
                <Nav.Item >
                    <Nav.Link
                        style={{ fontSize: '16px' }}
                        id='pending'
                        eventKey={'pending'}
                        onClick={e => setTab(e.target.id)}
                    >
                        Pending
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        style={{ fontSize: '16px' }}
                        id='completed'
                        eventKey={'completed'}
                        onClick={e => setTab(e.target.id)}
                    >
                        Completed
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            {user.products.filter(product => {
                if (tab === 'pending') {
                    return moment(product.endDate).isAfter(moment())
                } else {
                    return true
                }
            }).map((product, i) => {
                var filter;
                if (tab === 'pending') {
                    filter = pick => pick.products.find(prod => prod === product.id) && pick.status === 'pending';
                } else {
                    filter = pick => pick.products.find(prod => prod === product.id) && pick.status !== 'pending';
                }
                return (
                    <div key={i} style={{ marginBottom: '15px' }}>
                        <TableHeader>
                            {product.name}
                            {tab !== 'pending' ?
                                <Button
                                    style={{ marginLeft: 'auto' }}
                                    onClick={() => {
                                        setHistoryPicks(picks.filter(filter))
                                    }}
                                >
                                    View All
                                </Button>
                                :
                                <></>
                            }
                        </TableHeader>
                        <TableSubHeader>
                            {`Start date: ${moment(product.startDate).format('MM/DD/YYYY')} End Date: ${moment(product.endDate).year() === 3000 ? 'Recurring' : moment(product.endDate).format('MM/DD/YYYY')}`}
                        </TableSubHeader>
                        {!picks.filter(filter).length ?
                            <Alert variant='info'>
                                {tab === 'pending' ?
                                    'No upcoming plays.  Check back later.'
                                    :
                                    'No completed plays.  Check back later.'
                                }
                            </Alert>
                            :
                            <Table
                                headers={['Type', 'Play', 'Odds', 'Juice', 'Units', 'Grade']}
                                rows={tab === 'pending' ?
                                    generateRows(picks.filter(filter))
                                    :
                                    generateRows(picks.filter(filter).slice(-5), true)
                                }
                                striped={tab === 'pending' ? true : false}
                            />
                        }
                    </div>
                )
            })}
        </>
    );
}

export default Picks;