// Library imports
import React from 'react';
// Component imports
import Header from '../components/Header';
import PricingSection from '../components/PricingSection';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import FAQ from '../components/FAQ';

const Packages = () => {
    return (
        <div className='page-wrapper'>
            <Header
                currentPage={'packages'}
            />
            <PricingSection />
            <FAQ />
            <Testimonials variant='white' />
            <Footer />
        </div>
    )
}

export default Packages