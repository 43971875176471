import fire from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyBSDseMcIFjzu7kponcoGnyQ9oMTd9iBEE",
    authDomain: "plainsmansports.firebaseapp.com",
    projectId: "plainsmansports",
    storageBucket: "plainsmansports.appspot.com",
    messagingSenderId: "975006913426",
    appId: "1:975006913426:web:0718cb47b15212d805e74b"
};

if (!fire.apps.length) {
    fire.initializeApp(firebaseConfig);
}