// Library imports
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
// CSS imports
import '../css/CompareList.css';

const CompareList = ({ labels = [], dataSetOne = [], dataSetOneTitle, dataSetOneTitleColor = '#000', dataSetTwo = [], dataSetTwoTitle, dataSetTwoTitleColor = '#000' }) => {
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col xs={5} sm={5} style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className='card-title' style={{ color: dataSetOneTitleColor, backgroundColor: '#fff' }}>
                            {dataSetOneTitle}
                        </span>
                        {dataSetOne.map((value, i) => {
                            return (
                                <span className={`data-value ${value.class}`} key={i}>{value.value}</span>
                            )
                        })}
                    </Col>
                    <Col xs={2} sm={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {labels.map((value, i) => {
                            return (
                                <span className='data-label' key={i}>{value}</span>
                            )
                        })}
                    </Col>
                    <Col xs={5} sm={5} style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className='card-title' style={{ color: dataSetTwoTitleColor, backgroundColor: '#fff', alignSelf: 'flex-end' }}>
                            {dataSetTwoTitle}
                        </span>
                        {dataSetTwo.map((value, i) => {
                            return (
                                <span className={`data-value ${value.class}`} key={i}>{value.value}</span>
                            )
                        })}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default CompareList