// Library imports
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// Component imports
import Header from '../components/Header';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import PrimaryButton from '../components/BootstrapElements/PrimaryButton';
// Style imports
import { HeroMainText, HeroSubText, HeroTopLine } from '../components/HeroSection/styles';
import { HeroWrapper } from '../components/HeroSection/styles';

const Mission = () => {
    return (
        <div className='page-wrapper'>
            <Header
                currentPage={'home'}
            />
            <HeroWrapper>
                <Container>
                    <PrimaryButton
                        text='Return'
                        style={{ marginTop: '-50px', }}
                        size='sm'
                        onClick={() => window.location = '/home'}
                    />
                    <HeroMainText>
                        They have a team dedicated to beat you...
                    </HeroMainText>
                    <HeroMainText>
                        You need a winning team to fight back.
                    </HeroMainText>
                    <HeroSubText style={{ textIndent: '25px', letterSpacing: '0px', lineHeight: '32px' }}>
                        At Plainsman Sports, our mission is to empower sports bettors with the knowledge, tools, and strategies they need to consistently outperform the sportsbook. We understand that betting on sports is more than just a game; it&#39;s a skill that requires dedication, analysis, and a deep understanding of the sports landscape.
                    </HeroSubText>
                    <HeroSubText style={{ textIndent: '25px', letterSpacing: '0px', lineHeight: '32px' }}>
                        Our team of experienced sports analysts and industry experts work tirelessly to provide our community with accurate and insightful information that can give them an edge in the highly competitive world of sports betting. Through comprehensive research, data analysis, and the utilization of cutting-edge technologies, we strive to deliver the most reliable predictions and actionable insights to our members.
                    </HeroSubText>
                    <HeroSubText style={{ textIndent: '25px', letterSpacing: '0px', lineHeight: '32px' }}>
                        Transparency, integrity, and responsible gambling are at the core of our operations. We are
                        committed to providing a safe and trusted platform that promotes responsible betting
                        practices and helps our members make informed decisions. We believe in fostering a
                        supportive and collaborative community, where sports enthusiasts can exchange ideas, share strategies, and learn from each other&#39;s experiences.
                    </HeroSubText>
                    <HeroSubText style={{ textIndent: '25px', letterSpacing: '0px', lineHeight: '32px' }}>
                        Plainsman Sports is driven by a passion for sports and a dedication to helping our
                        members achieve their betting goals. We aim to be the go-to resource for sports bettors,
                        offering a comprehensive suite of tools, educational materials, and personalized guidance.
                        By equipping our community with the knowledge and skills necessary to beat the sportsbook, we aim to revolutionize the way sports betting is approached, ensuring long-term success for our members.
                    </HeroSubText>
                    <HeroSubText style={{ textIndent: '25px', letterSpacing: '0px', lineHeight: '32px' }}>
                        Join us at Plainsman Sports as we embark on this exciting journey of challenging the status
                        quo and redefining the sports betting landscape. Together, we can turn the odds in our
                        favor and achieve new heights of profitability and enjoyment in the world of sports betting.
                    </HeroSubText>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '15px' }}>
                            <PrimaryButton
                                text='Package Options'
                                size="lg"
                                onClick={() => window.location = '/packages'}
                            />
                        </Col>
                    </Row>
                </Container>
            </HeroWrapper>
            <Testimonials variant='silver' />
            <Footer />
        </div >
    )
}

export default Mission;