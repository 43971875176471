// Library imports
import React, { useState, useEffect } from "react";
import { Row, Col, Nav } from 'react-bootstrap';
// Component imports
import CompareList from "../../../../CompareList";
import { Loader } from "../../../../Loader";
// Controller imports
import { keyFactorData } from "./KeyFactorController";
// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { addLoader, removeLoader } from "../../../../../store/slices/appSlice";
// Style imports
import { DetailSection, SectionTitle } from '../styles';

const KeyFactors = ({ game, sport }) => {
    // Redux
    const { loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [type, setType] = useState('off');
    const [dataFields, setDataFields] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(addLoader('factors'))
                const res = await keyFactorData(sport, game.awayTeam, game.homeTeam, type);
                setDataFields(res);
            } catch (error) {
                console.log(`Error getting key factor objects for: ${sport}\n${error.message}`);
            } finally {
                dispatch(removeLoader('factors'))
            }
        }
        fetchData();
    }, [type]);

    return (
        <DetailSection>
            <SectionTitle>
                Key Factors
            </SectionTitle>
            <Row>
                <Col>
                    <Nav variant="tabs" className='mb-2' defaultActiveKey={type}>
                        <Nav.Item>
                            <Nav.Link onClick={(e) => setType('off')} eventKey='off'>Offense</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={(e) => setType('def')} eventKey='def'>Defense</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            {loaders.find(loader => loader === 'factors') ?
                <Loader style={{ marginTop: '15px' }} />
                :
                <CompareList
                    labels={dataFields[2]}
                    dataSetOneTitle={game.awayTeam.teamName}
                    dataSetOneTitleColor={game.awayTeam.color}
                    dataSetOne={dataFields[0]}
                    dataSetTwoTitle={game.homeTeam.teamName}
                    dataSetTwoTitleColor={game.homeTeam.color}
                    dataSetTwo={dataFields[1]}
                />
            }
        </DetailSection>
    )
}

export default KeyFactors