// Library imports
import React from 'react';
import { Table as BootstrapTable, Nav } from 'react-bootstrap';
// Util imports
import { capitalize } from '../../utils/Global';

const Table = ({ style = {}, headers = [], rows = [], tabs = {}, responsive = true, loading = false, hover = true, striped = true }) => {
    return (
        <div style={style}>
            {Object.keys(tabs).length ?
                <Nav variant="tabs" defaultActiveKey={tabs.defaultActiveKey}>
                    {tabs.keys.map((key, i) => {
                        return (
                            <Nav.Item key={i}>
                                <Nav.Link
                                    eventKey={key}
                                    onClick={() => tabs.handleOnNavClick(key)}
                                >
                                    {capitalize(key)}
                                </Nav.Link>
                            </Nav.Item>
                        )
                    })}
                </Nav>
                :
                <></>
            }
            <BootstrapTable
                striped={striped}
                bordered
                hover={hover}
                responsive={responsive}
            >
                <thead>
                    <tr>
                        {headers.map((header, i) => {
                            return <th key={i}>{header}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </BootstrapTable>
        </div>
    );
}

export default Table;