// Library imports
import React, { useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
// Component imports
import StripePaymentForm from '../StripeForm';
// Redux imports
import { useSelector } from 'react-redux';
// Firebase imports
import firebase from 'firebase';
import { makePayment } from '../../controllers/PaymentController';

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Payment = ({ setError }) => {
    const { data } = useSelector(state => state.register);

    useEffect(() => {
        if (!data.plan) {
            window.location = '/packages'
        }
    })

    return (
        <ListGroup variant='flush'>
            <ListGroup.Item style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold', marginBottom: '5px' }}>Display Name:</span>
                <span>{data.displayName}</span>
            </ListGroup.Item>
            <ListGroup.Item style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold', marginBottom: '5px' }}>Email:</span>
                <span>{data.email}</span>
            </ListGroup.Item>
            <ListGroup.Item style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold', marginBottom: '5px' }}>Package:</span>
                <span>{data.plan?.name}</span>
            </ListGroup.Item>
            <ListGroup.Item style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold', marginBottom: '5px' }}>Description:</span>
                <span>{data.plan?.description}</span>
            </ListGroup.Item>
            <ListGroup.Item style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold', marginBottom: '5px' }}>Price:</span>
                <span>${data.plan?.price}</span>
            </ListGroup.Item>
            <ListGroup.Item style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold', marginBottom: '5px' }}>Payment Details:</span>
                <Elements stripe={stripeTestPromise}>
                    <StripePaymentForm
                        handleSubmit={async (id) => {
                            try {
                                if (data.email && data.password) {
                                    let res = await makePayment({ paymentId: id, product: data.plan, customer: data });
                                    await makePayment({ payment_intent_id: res.payment_intent_id, product: data.plan, customer: data });
                                    await firebase.auth().createUserWithEmailAndPassword(data.email, data.password);
                                    await firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).set({
                                        displayName: data.displayName,
                                        email: data.email,
                                        uid: firebase.auth().currentUser.uid,
                                        products: [{ ...data.plan, startDate: moment().format(), endDate: moment().add(1, 'months').format() }]
                                    });
                                    window.location = '/app'
                                } else {
                                    setError('Something went wrong.  Username and password not set.  Please try again');
                                }
                            } catch (error) {
                                setError(error.message);
                                console.log(`Register error\n${error}`)
                            }
                        }}
                        setError={setError}
                    />
                </Elements>
            </ListGroup.Item>
        </ListGroup >
    );
}

export default Payment