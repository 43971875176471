// Library imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// Firebase imports
import firebase from 'firebase';

const initialState = {
    user: null,
    loaders: []
}

export const fetchUser = createAsyncThunk(
    "user/fetchUser",
    async (_, { rejectWithValue }) => {
        try {
            const snapshot = await firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).get();
            if (snapshot.exists) {
                return snapshot.data();
            } else {
                throw new Error('User information not found.')
            }
        } catch (err) {
            //return rejectWithValue([], err);
        }
    }
);

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            state.user = { ...payload }
        },
        addLoader: (state, { payload }) => {
            state.loaders = [...state.loaders, payload];
        },
        removeLoader: (state, { payload }) => {
            state.loaders = state.loaders.filter(loader => loader !== payload);
        }
    },
    extraReducers: {
        [fetchUser.fulfilled]: (state, { payload }) => {
            state.user = payload;
        },
        [fetchUser.rejected]: (state, { payload, error }) => {
            console.log(error);
        }
    }
});

// Action creators 
export const { setUser, addLoader, removeLoader } = appSlice.actions;

export default appSlice.reducer;