// Library imports
import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Card, ListGroup, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
// Component imports
import Table from '../../Table';
import PrimaryButton from '../../BootstrapElements/PrimaryButton';
import { Loader } from '../../Loader';
// Controller imports
import { addPackage, getUsers, deletePackage } from '../../../controllers/UserController';
import { getAllProducts } from '../../../controllers/ProductController';
// Style imports
import { TableHeader } from '../Picks/styles';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { addLoader, removeLoader } from '../../../store/slices/appSlice';
// Config imports
import { ProductList } from './styles';
import { ProductsContainer } from '../styles';
// Firebase imports
import firebase from 'firebase';


const Users = () => {
    // Redux
    const dispatch = useDispatch();
    const { loaders } = useSelector(state => state.app)
    // Local state
    const [users, setUsers] = useState([]);
    const [manageUser, setManageUser] = useState(null);
    const [show, setShow] = useState(false);
    const [showExtendModal, setShowExtendModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [newProduct, setNewProduct] = useState(null);
    const [endDate, setEndDate] = useState('');
    const [productToExtend, setProductToExtend] = useState(null);

    const handleClose = () => {
        setNewProduct(null);
        setEndDate('');
        setShow(false);
    }

    const handleExtendModalClose = () => {
        setProductToExtend(null);
        setEndDate('');
        setShowExtendModal(false);
    }

    const handleShow = () => setShow(true);

    const handleShowExtendModal = () => setShowExtendModal(true);

    const refreshUsers = async () => {
        try {
            const res = await getUsers();
            if (manageUser) {
                const newManageUser = res.find(user => user.uid === manageUser.uid);
                setManageUser(newManageUser);
            }
            setUsers(res)
        } catch (error) {
            console.log(`Error geting users. ${error}`);
        }
    }

    const extendPackage = async () => {
        const products = [...manageUser.products].map(product => {
            if (product.id === productToExtend.id) {
                product.endDate = moment(endDate).format('MM/DD/YYYY');
            }
            return product
        })
        await firebase.firestore().collection('users').doc(manageUser.uid).set({
            products
        }, { merge: true })
    }

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(addLoader('users'))
                await refreshUsers();
                let productsRes = await getAllProducts();
                productsRes = productsRes.filter(product => product.id !== 'free');
                setProducts(productsRes);
            } catch (error) {
                console.log(`Error geting users and/or products. ${error}`);
            } finally {
                dispatch(removeLoader('users'))
            }
        }
        fetchData();
    }, [])

    if (loaders.find(loader => loader === 'users')) {
        return <Loader loading={true} />
    }

    if (manageUser) {
        return (
            <>
                <TableHeader>
                    {manageUser.displayName}
                </TableHeader>
                <PrimaryButton
                    text={<><FontAwesomeIcon icon={faArrowLeft} /> Back</>}
                    style={{ marginRight: '5px' }}
                    variant='danger'
                    onClick={() => setManageUser(null)}
                />
                <PrimaryButton
                    text={<><FontAwesomeIcon icon={faPlusCircle} /> Add Product</>}
                    variant='success'
                    style={{}}
                    onClick={handleShow}
                />
                <ProductsContainer>
                    {!manageUser.products || !manageUser.products.length ?
                        <Alert variant='info'>
                            This user does not have any products.
                        </Alert>
                        :
                        manageUser.products.map((product, i) => {
                            return (
                                <Card style={{ width: '300px', marginRight: '15px' }}>
                                    <Card.Body style={{ flex: 1 }}>
                                        <Card.Title>{product.name}</Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">{product.id}</Card.Subtitle>
                                    </Card.Body>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>Start Date: {moment(product.startDate).format('MM/DD/YYYY')}</ListGroup.Item>
                                        <ListGroup.Item>End Date: {moment(product.endDate).format('MM/DD/YYYY')}</ListGroup.Item>
                                    </ListGroup>
                                    <Card.Body>
                                        <Card.Link
                                            onClick={async () => {
                                                try {
                                                    await deletePackage(manageUser, product);
                                                    await refreshUsers();
                                                } catch (error) {
                                                    console.log(`Error deleting package\n${error}`)
                                                }
                                            }}
                                        >
                                            Remove
                                        </Card.Link>
                                        <Card.Link
                                            onClick={() => {
                                                setProductToExtend(product)
                                                handleShowExtendModal();
                                            }}
                                        >
                                            Extend
                                        </Card.Link>
                                    </Card.Body>
                                </Card>
                            )
                        })
                    }
                </ProductsContainer>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{newProduct ? newProduct.name : 'Add Product'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {newProduct ?
                            <>
                                <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                    End Date
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(event) => {
                                        setEndDate(event.target.value)
                                    }}
                                />
                            </>

                            :
                            <ProductList>
                                {products.map((product, i) => {
                                    return (
                                        <Button
                                            style={{ marginBottom: '10px' }}
                                            onClick={() => setNewProduct(product)}
                                        >
                                            {product.name}
                                        </Button>
                                    )
                                })}
                            </ProductList>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {newProduct ?
                            <Button
                                variant="success"
                                onClick={async () => {
                                    try {
                                        var finalProduct = { ...newProduct }
                                        console.log(endDate)
                                        finalProduct.timespan = {
                                            hardDate: endDate
                                        }
                                        await addPackage(manageUser.uid, finalProduct);
                                        await refreshUsers();
                                        handleClose();
                                    } catch (error) {
                                        console.log(`Error adding package to user\n${error}`)
                                    } finally {

                                    }
                                }}
                            >
                                Save Changes
                            </Button>
                            :
                            <></>
                        }
                    </Modal.Footer>
                </Modal>
                <Modal show={showExtendModal} onHide={handleExtendModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Extend Date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                            End Date
                        </Form.Label>
                        <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(event) => {
                                setEndDate(event.target.value)
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleExtendModalClose}>
                            Close
                        </Button>
                        <Button
                            variant="success"
                            onClick={async () => {
                                try {
                                    if (endDate) {
                                        console.log('Extend package')
                                        await extendPackage();
                                    }
                                    handleExtendModalClose();
                                } catch (error) {
                                    console.log(`Error extending user package\n${error}`)
                                } finally {

                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            <TableHeader>
                User List
            </TableHeader>
            <Table
                headers={['Username', 'Email', 'Edit', '# of Products']}
                rows={users.map((user, i) => {
                    const products = user.products?.length ? user.products : [];
                    var rowClass = '';
                    products.forEach(product => {
                        if (moment(product.endDate).isBefore(moment())) {
                            rowClass = 'table-danger';
                        }
                    })
                    return (
                        <tr className={rowClass} key={i}>
                            <td>{user.displayName}</td>
                            <td>{user.email}</td>
                            <td>
                                <PrimaryButton
                                    onClick={() => {
                                        setManageUser({ ...user })
                                    }}
                                    text='Manage Package(s)'
                                />
                            </td>
                            <td>{products.length}</td>
                        </tr>
                    )
                })}
            />
        </>
    )
}

export default Users;