// Library imports
import React from 'react';
// Component imports
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import PrimaryButton from '../components/BootstrapElements/PrimaryButton';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import FAQ from '../components/FAQ';
// Image imports
import heroImage from '../img/logo-slogan-join.png';
import personImage from '../img/person.png';
import makeMoneyImage from '../img/make-money.png';

const Home = () => {
    return (
        <div className='page-wrapper'>
            <Header
                currentPage={'home'}
            />
            <HeroSection
                variant='dark'
                topLine='They have a team dedicated to beat you, you need a winning team to fight back.'
                mainText='Are You Tired Of Losing To The Sportsbook?'
                subText="At Plainsman Sports, our mission is to empower sports bettors with the knowledge, tools, and strategies they need to consistently outperform the sportsbook. We understand that betting on sports is more than just a game; it's a skill that requires dedication, analysis, and a deep understanding of the sports landscape."
                image={heroImage}
                imageSide='left'
                buttons={[
                    <PrimaryButton
                        text='Get Started'
                        size="lg"
                        onClick={() => window.location = '/packages'}
                    />
                ]}
            />
            <HeroSection
                variant='light'
                topLine='Who Leads Plainsman Sports?'
                mainText="Hi! I'm Herb West"
                subText="Herb West is a name that strikes fear into the hearts of any sportsbook or bookie alike that has faced off against him. Known as a professional sports bettor and poker player, Herb's journey to success was far from easy. He learned the ropes the hard way after experiencing countless losses at the hands of the sportsbooks, paying his local bookies way too often.  Hi, my name is Herb West and this is my story...."
                image={personImage}
                imageSide='right'
                buttons={[
                    <PrimaryButton
                        text='Read My Story'
                        size="lg"
                        onClick={() => {
                            window.location = '/my-story'
                        }}
                    />
                ]}
            />
            <FAQ />
            <HeroSection
                variant='white'
                topLine='Does This Sound Like You?'
                mainText='Start Winning Today'
                list={[
                    <span><b>Do you feel like...</b> you just can't win?</span>,
                    <span><b>Does it seem...</b> you can't find any reliable resources or consultants?</span>,
                    <span><b>Are you frustrated that...</b> the lines and odds seem to always move against you?</span>,
                    <span><b>And when it comes down to it, do you...</b> lose more than you win?</span>,
                ]}
                image={makeMoneyImage}
                imageSide='right'
                buttons={[
                    <PrimaryButton
                        text='Package Options'
                        size="lg"
                        onClick={() => window.location = '/packages'}
                    />
                ]}
            />
            <Testimonials variant='silver' />
            <Footer />
        </div>
    )
}

export default Home