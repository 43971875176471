// Library imports
import React from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
// Component imports
import PublicMoneyPercent from './Sections/PublicMoneyPercent';
import LineData from './Sections/LineData';
import KeyFactors from './Sections/KeyFactors';
import Schedules from './Sections/Schedules';
import History from './Sections/History';
import Pitchers from './Sections/Pitchers';
// Style imports
import { CloseButton, DetailSection, SectionSubTitle, SectionTitle, TeamName } from './styles';

const GameDetails = ({ game, sport, setActiveGame }) => {
    return (
        <div>
            <DetailSection>
                <SectionTitle>
                    <TeamName style={{ marginRight: '5px' }} color={game.awayTeam.color}>
                        {game.awayTeam.teamName}
                    </TeamName>
                    vs.
                    <TeamName style={{ marginLeft: '5px' }} color={game.homeTeam.color}>
                        {game.homeTeam.teamName}
                    </TeamName>
                </SectionTitle>
                <SectionSubTitle>
                    {moment(new Date(game.dateTime)).format("MM/DD hh:mm A")}
                </SectionSubTitle>
                <CloseButton>
                    <Button
                        variant='link'
                        onClick={() => setActiveGame(null)}
                    >
                        <FontAwesomeIcon icon={faClose} />
                    </Button>
                </CloseButton>
            </DetailSection>
            <LineData game={game} sport={sport} />
            <PublicMoneyPercent game={game} sport={sport} />
            <Pitchers game={game} sport={sport} />
            <KeyFactors game={game} sport={sport} />
            <Schedules game={game} sport={sport} />
            <History game={game} sport={sport} />
        </div>
    )
}

export default GameDetails