// Library imports
import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import moment from 'moment'
// Component imports
import Dropdown from '../../BootstrapElements/Dropdown';
import Table from '../../Table';
// Controller imports
import { getAllProducts } from '../../../controllers/ProductController';
import { addPick } from '../../../controllers/PickController';
// Style imports
import { TableHeader } from '../Picks/styles';
// Config imports
import { PRIMARY_COLOR } from '../../../config/theme';


const CreatePick = () => {
    // Local state
    const [products, setProducts] = useState([]);
    const [playData, setPlayData] = useState({
        sport: '',
        name: '',
        odds: '',
        juice: '',
        type: 'moneyline',
        gameTime: new Date()
    })
    const [pickData, setPickData] = useState({
        type: 'straight',
        products: ['big6'],
        plays: [],
        units: '',
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await getAllProducts();
                setProducts(res)
            } catch (error) {
                console.log(`Error geting products. ${error}`);
            } finally {

            }
        }
        fetchData();
    }, []);

    const triggerNotificationHandler = async () => {

    };

    return (
        <Row>
            <Col sm={8} md={8} lg={8} xl={8}>
                <TableHeader>
                    Create Pick
                </TableHeader>
                <Card>
                    <Card.Body>
                        <Form style={{ maxWidth: 800 }}>
                            <Row>
                                <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                    Bet Type
                                </Form.Label>
                                <Col>
                                    <Dropdown
                                        variant={'light'}
                                        dropdownStyle={{ border: '1px solid #ddd' }}
                                        value={pickData.type}
                                        setValue={(selectedKey) => setPickData({ ...pickData, type: selectedKey })}
                                        menuItems={['straight', 'parlay', 'teaser'].map(type => type)}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                    Product(s)
                                </Form.Label>
                                <Col>
                                    <Dropdown
                                        variant={'light'}
                                        dropdownStyle={{ border: '1px solid #ddd' }}
                                        value={pickData.products[0]}
                                        setValue={(selectedKey) => setPickData({ ...pickData, products: [selectedKey] })}
                                        menuItems={products.map(product => product.id)}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                    Units
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        type="number"
                                        value={pickData.units}
                                        onChange={e => setPickData({ ...pickData, units: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                    Add Play
                                </Form.Label>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                <span style={{ marginRight: '15px' }}>Type</span>
                                                <Dropdown
                                                    variant={'light'}
                                                    dropdownStyle={{ border: '1px solid #ddd' }}
                                                    value={playData.type}
                                                    setValue={(selectedKey) => setPlayData({ ...playData, type: selectedKey })}
                                                    menuItems={['moneyline', 'spread', 'total'].map(type => type)}
                                                />
                                            </div>
                                            <div style={{ marginTop: '15px' }}>
                                                <span style={{ marginRight: '15px' }}>Sport</span>
                                                <Form.Control
                                                    type="text"
                                                    value={playData.sport}
                                                    onChange={e => setPlayData({ ...playData, sport: e.target.value })}
                                                />
                                            </div>
                                            <div style={{ marginTop: '15px' }}>
                                                <span style={{ marginRight: '15px' }}>Name</span>
                                                <Form.Control
                                                    type="text"
                                                    value={playData.name}
                                                    onChange={e => setPlayData({ ...playData, name: e.target.value })}
                                                />
                                            </div>
                                            <div style={{ marginTop: '15px' }}>
                                                <span style={{ marginRight: '15px' }}>Odds</span>
                                                <Form.Control
                                                    type="number"
                                                    value={playData.odds}
                                                    onChange={e => setPlayData({ ...playData, odds: e.target.value })}
                                                />
                                            </div>
                                            {playData.type !== 'moneyline' ?
                                                <div style={{ marginTop: '15px' }}>
                                                    <span style={{ marginRight: '15px' }}>Juice</span>
                                                    <Form.Control
                                                        type="number"
                                                        value={playData.juice}
                                                        onChange={e => setPlayData({ ...playData, juice: e.target.value })}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                            <Card.Link
                                                style={{ display: 'block', marginTop: '15px', color: PRIMARY_COLOR }}
                                                onClick={() => {
                                                    if (playData.name && playData.odds !== undefined && playData.odds !== '') {
                                                        setPickData({ ...pickData, plays: [...pickData.plays, playData] })
                                                        setPlayData({
                                                            sport: '',
                                                            name: '',
                                                            odds: '',
                                                            juice: '',
                                                            type: 'moneyline',
                                                            gameTime: new Date()
                                                        })
                                                    }
                                                }}
                                            >
                                                Add Play
                                            </Card.Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body >
                </Card>
            </Col>
            <Col sm={4} md={4} lg={4} xl={4}>
                <TableHeader>
                    Plays
                </TableHeader>
                <Table
                    headers={['Play', 'Odds', 'Juice']}
                    rows={pickData.plays.map((play, i) => {
                        return (
                            <tr key={i}>
                                <td>
                                    <span>{play.name}</span>
                                </td>
                                <td>
                                    <span>{play.odds}</span>
                                </td>
                                <td>
                                    <span>{play.type === 'moneyline' ? 'ML' : play.juice}</span>
                                </td>
                            </tr>
                        );
                    })}
                />
                <Row className='mt-2'>
                    <Col>
                        <Button
                            variant='success'
                            style={{ width: '100%' }}
                            onClick={async () => {
                                try {
                                    pickData.status = 'pending';
                                    pickData.time = moment().format();
                                    await addPick(pickData);
                                    setPlayData({
                                        sport: '',
                                        name: '',
                                        odds: '',
                                        juice: '',
                                        type: 'moneyline'
                                    })
                                    setPickData({
                                        type: '',
                                        products: [],
                                        plays: [],
                                        units: ''
                                    });
                                    await triggerNotificationHandler();
                                } catch (error) {
                                    console.log(`Error adding pick\n${error}`);
                                }
                            }}
                        >
                            Send Pick
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default CreatePick