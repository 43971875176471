// Library imports
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Card, Row, Col } from "react-bootstrap";
// Component imports
import Table from '../../../../Table';
import { Loader } from "../../../../Loader";
// Controller imports
import { getGameTeamData } from "../../../../../controllers/GetDataController";
// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { addLoader, removeLoader } from "../../../../../store/slices/appSlice";
// Style imports
import { DetailSection, SectionTitle } from '../styles';

const Schedules = ({ game, sport }) => {
    // Redux
    const { loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [awayData, setAwayData] = useState({})
    const [homeData, setHomeData] = useState({})

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(addLoader('schedules'))
                const { awayTeamData, homeTeamData } = await getGameTeamData(sport, game);
                setAwayData(awayTeamData ? awayTeamData : {});
                setHomeData(homeTeamData ? homeTeamData : {});
            } catch (error) {

            } finally {
                dispatch(removeLoader('schedules'))
            }
        }
        fetchData();
    }, []);

    const createScheduleJSX = (team) => {
        const schedule = team.teamId === game.awayTeam.teamId ? awayData.schedule : homeData.schedule;
        if (!schedule) return <></>;
        return (
            <Card style={{ border: 0 }}>
                <Card.Body style={{ padding: 0 }}>
                    <Card.Title className='card-title h4 pt-2 pb-2 mb-0' style={{ color: team.color, backgroundColor: '#fff', fontWeight: 800 }}>
                        {team.teamName}
                    </Card.Title>
                    <Card.Subtitle style={{ display: 'flex', marginBottom: '5px' }}>
                        <div style={{ marginRight: '15px' }}>
                            <span style={{ fontWeight: 'bold' }}>Overall: </span>
                            <span>{team.recordData.record}</span>
                        </div>
                        <div style={{ marginRight: '15px' }}>
                            <span style={{ fontWeight: 'bold' }}>{team.teamId === game.awayTeam.teamId ? `Away: ` : `Home: `}</span>
                            <span>{team.teamId === game.awayTeam.teamId ? `${team.recordData.awayRecord}` : `${team.recordData.homeRecord}`}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>Strength: </span>
                            <span>#{team.sos}</span>
                        </div>
                    </Card.Subtitle>
                    <Table
                        headers={['Game',
                            schedule.filter(game => game.points !== ""
                                && game.points !== undefined).length === 0 ? 'Time' : 'Final'
                        ]}
                        rows={schedule.filter(game => game.points).slice(0, 5).length === 0 ?
                            schedule.map((game, i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            <div>
                                                <span style={{ fontSize: '12px', fontWeight: 400, color: '#666' }}>
                                                    {moment(game.date).format('MM/DD/YY')}
                                                </span>
                                                <span style={{ fontWeight: 'bold', color: '#444', fontSize: '14px', whiteSpace: 'nowrap' }}>
                                                    {game.location === 'away' ? '@' : 'vs.'} {game.oppAbbr}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            {moment(game.date, 'hh:mm').format('hh:mm')}
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            schedule.filter(game => game.points).slice(0).reverse().slice(0, 5).map((game, i) => {
                                var tableClass = '';
                                var textClass = '';
                                if (game.result === 'W') {
                                    tableClass = 'table-success';
                                    textClass = 'text-success';
                                }
                                if (game.result === 'L') {
                                    tableClass = 'table-danger';
                                    textClass = 'text-danger';
                                }
                                return (
                                    <tr
                                        key={i}
                                        className={tableClass}
                                    >
                                        <td>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span className={textClass} style={{ fontSize: 12, fontWeight: 400, color: '#666' }}>
                                                    {moment(game.date).format('MM/DD/YY')}
                                                </span>
                                                <span className={textClass} style={{ fontWeight: 'bold', color: '#444', fontSize: '14px', whiteSpace: 'nowrap' }}>
                                                    {game.location === 'away' ? '@' : 'vs.'} {game.oppAbbr}
                                                </span>
                                            </div>
                                        </td>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                            <span className={textClass} style={{ fontWeight: 'bold', color: '#444', fontSize: '14px' }}>
                                                {game.points}-{game.opp_pts} {sport === 'mlb' ? game.result : game.streak}
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    />
                </Card.Body>
            </Card>
        )
    }

    return (
        <DetailSection>
            <SectionTitle>
                Schedules
            </SectionTitle>
            {loaders.find(loader => loader === 'schedules') ?
                <Loader style={{ marginTop: '15px' }} />
                :
                <Row>
                    <>
                        <Col>
                            {createScheduleJSX(game.awayTeam)}
                        </Col>
                        <Col>
                            {createScheduleJSX(game.homeTeam)}
                        </Col>
                    </>
                </Row>
            }
        </DetailSection>
    )
}

export default Schedules