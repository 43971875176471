// Library and Component imports
import React from "react";
import { SocialIcon } from 'react-social-icons';
// CSS imports
import "../css/Footer.css";

const Footer = () => {
    return (
        <footer className="main_footer">
            <div className="lower">
                <div className="inner">
                    <ul className="footer_sitemap">
                        <li className="sitemap_block">
                            <ul>
                                <li className="sitemap_heading">
                                    <a title="General" href="/">General</a>
                                </li>
                                <li>
                                    <a title="Home" href="/">Home</a>
                                </li>
                            </ul>
                        </li>
                        <li className="sitemap_block">
                            <ul>
                                <li className="sitemap_heading">
                                    <a href="/login">Site Map</a>
                                </li><li>
                                    <a title="login" href="/login">Log in</a>
                                </li>
                                <li>
                                    <a title="app" href="/packages">Packages</a>
                                </li>
                            </ul>
                        </li>
                        <li className="sitemap_block">
                            <ul>
                                <li className="sitemap_heading">
                                    <a href="/">Legal</a>
                                </li>
                                <li>
                                    <a rel="noopener noreferrer" target="_blank" title="privacy" href="https://firebasestorage.googleapis.com/v0/b/plainsmansports.appspot.com/o/privacy-policy-plainsman-sports.txt?alt=media&token=b7309711-c171-479b-8e84-9ef0d6624cf7">Privacy Policy</a>
                                </li>
                                <li>
                                    <a rel="noopener noreferrer" target="_blank" title="tandc" href="https://firebasestorage.googleapis.com/v0/b/plainsmansports.appspot.com/o/terms-conditions-plainsman-sports.txt?alt=media&token=67c11bf6-bac3-4822-aad0-c4b440d32046">Terms and Conditions</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div style={{ marginTop: '2rem' }} id='social-icons'>
                        <SocialIcon url="https://www.instagram.com/plainsmansports/?igshid=MzRlODBiNWFlZA%3D%3D" />
                    </div>
                </div>
            </div>
            <div className="extra">
                <div className="inner">
                    <small>Copyright © 2023 plainsmanSports.</small>
                </div>
            </div>
        </footer >
    );
};

export default Footer;