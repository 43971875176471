// Library imports
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
// Config imports
import { PRIMARY_COLOR } from '../../config/theme';
import { devices } from '../../config/constants';

export const HeroWrapper = styled.div`
    padding: 150px 0px;
    --bs-bg-opacity: 1;
    background-color: ${props => props.variant === 'dark' ? 'rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))' : props.variant === 'silver' ? '#f5f5f5' : '#fff'} !important;
`

export const HeroTopLine = styled.div`
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 16px;
    color: ${PRIMARY_COLOR};
`

export const HeroMainText = styled.h1`
    font-size: 54px;
    font-weight: 800;
    line-height: 1.1;
    margin: 0px 0px 24px 0px;
    padding: 0px;
    color: ${props => props.variant === 'dark' ? '#fff' : '	#0e1111'};
`
export const HeroSubText = styled.p`
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: -.014em;
    color: ${props => props.variant === 'dark' ? '#f5f5f5' : '#414a4c'};
`
export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 40px;
`
export const ImageColumn = styled(Col)`
    padding: ${props => props.imageSide === 'left' ? '0px 0px 0px 100px' : '0px 100px 0px 0px'};
    @media all and ${devices.md} {
        padding: 0px 15px;
        margin-top: 25px;
    }
`

export const HeroImage = styled.img`
    max-width: 100%;
    display: block;
`