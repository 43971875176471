// Library imports
import React, { useState } from 'react';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { Form, Button } from 'react-bootstrap';
// CSS imports
import '../../css/Stripe.css';

const StripePaymentForm = ({ autoPay = false, handleSubmit, setError }) => {
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    return (
        <Form
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            onSubmit={async (e) => {
                e.preventDefault();
                try {
                    setLoading(true);
                    const { error, paymentMethod } = await stripe.createPaymentMethod({
                        type: 'card',
                        card: elements.getElement(CardElement)
                    });
                    if (!error) {
                        try {
                            const { id } = paymentMethod;
                            await handleSubmit(id);
                        } catch (error) {
                            setError(`Error: ${error.message}`);
                        }
                    } else {
                        setError(`Error: ${error.message}`);
                    }
                } catch (error) {
                    console.log(`Error creating payment intent\n${error}`)
                    setError(`[Error] Oops something went wrong.  Please refresh and try again.`);
                } finally {
                    setLoading(false);
                }
            }}>
            <CardElement options={{
                style: {
                    base: {
                        iconColor: '#2b7dc5',
                        color: '#333',
                        fontWeight: '500',
                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                        fontSize: '20px',
                        fontSmoothing: 'antialiased',
                        '::placeholder': {
                            color: '#c6c6c6',
                        },
                    },
                    invalid: {
                        iconColor: '#8b0000',
                        color: '#8b0000',
                    },
                },
            }} />
            <Button
                style={{ width: '100%' }}
                variant="primary"
                type="submit"
                disabled={loading}
            >
                Purchase
            </Button>
        </Form>
    )
}

export default StripePaymentForm;