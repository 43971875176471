// Library imports
import React, { useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHome, faUserCircle, faStore, faBook, faPowerOff
} from "@fortawesome/free-solid-svg-icons";
// Image imports
import logo from '../img/logo.png';
// Firebase imports
import firebase from 'firebase';

const staticLinks = [
    {
        id: "home",
        icon: <FontAwesomeIcon style={{ marginRight: '2px' }} icon={faHome} />,
        label: "Home",
        disabled: false
    },
    {
        id: "packages",
        icon: <FontAwesomeIcon style={{ marginRight: '2px' }} icon={faStore} />,
        label: "Packages",
        disabled: false
    },
    {
        id: "blog",
        icon: <FontAwesomeIcon style={{ marginRight: '2px' }} icon={faBook} />,
        label: "Blog",
        disabled: false
    },
    {
        id: "login",
        icon: <FontAwesomeIcon style={{ marginRight: '2px' }} icon={faUserCircle} />,
        label: "Log in",
        disabled: false
    }
]

const appLinks = [
    {
        id: "logout",
        icon: <FontAwesomeIcon style={{ marginRight: '2px' }} icon={faPowerOff} />,
        label: "Logout",
        disabled: false
    },
]

const Header = ({ currentPage }) => {
    // Local state
    const [activeKey, setActiveKey] = useState(currentPage);

    const getLinkArray = () => {
        if (currentPage === 'app') {
            return appLinks;
        } else {
            return staticLinks;
        }
    };

    const changeMainPage = e => {
        const pageName = e.currentTarget.id;
        setActiveKey(pageName);
        if (pageName === 'home') return window.location = "/";
        if (pageName === "packages") return window.location = "/packages";
        if (pageName === 'blog') return window.location = "/blog"
        if (pageName === 'login') return window.location = "/app";
        if (pageName === 'logout') {
            firebase.auth().signOut()
        }
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt="logo"
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    plainsmanSports
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav activeKey={activeKey} style={{ marginLeft: 'auto' }}>
                        {getLinkArray().map((link, i) => {
                            return (
                                <Nav.Link
                                    key={i}
                                    disabled={link.disabled}
                                    id={link.id}
                                    eventKey={link.id}
                                    onClick={changeMainPage}
                                    style={{ marginRight: i === getLinkArray().length - 1 ? '0px' : '15px' }}
                                >
                                    {link.icon} {`${link.label}`}
                                </Nav.Link>
                            )
                        })}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header