// Library imports
import React, { useState, useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import moment from 'moment';
// Component imports 
import SportsNav from '../../../SportsNav';
import { Loader } from '../../Loader';
// Controller imports
import { getAllGameData } from '../../../controllers/GetDataController';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { addLoader, removeLoader } from '../../../store/slices/appSlice';
// Style imports
import { GameCard, GameContainer } from './styles';
import GameDetails from './GameDetails';

const Games = () => {
    // Redux imports
    const { loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [sport, setSport] = useState('mlb');
    const [gameData, setGameData] = useState([]);
    const [activeGame, setActiveGame] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(addLoader('games'))
                const gameInfo = await getAllGameData(sport);
                setGameData(gameInfo);
            } catch (error) {
                console.log(`Error getting game data\n${error}`);
            } finally {
                dispatch(removeLoader('games'))
            }
        }
        fetchData();
    }, [sport]);

    if (activeGame) {
        return (
            <GameDetails game={activeGame} sport={sport} setActiveGame={setActiveGame} />
        )
    }

    return (
        <div>
            <SportsNav
                click={setSport}
                all={false}
                defaultValue={sport}
            />
            {loaders.find(loader => loader === 'games') ?
                <Loader style={{ marginTop: '15px' }} />
                :
                <GameContainer>
                    {gameData.map(game => {
                        return (
                            <GameCard>
                                <Card.Body>
                                    <Card.Title>
                                        <span style={{ color: game.awayTeam.color, marginRight: '5px' }}>{game.awayTeam.teamName}</span>
                                        vs.
                                        <span style={{ color: game.homeTeam.color, marginLeft: '5px' }}>{game.homeTeam.teamName}</span>
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{moment(new Date(game.dateTime)).format("MM/DD hh:mm A")}</Card.Subtitle>
                                    <Button
                                        variant="light"
                                        size="sm"
                                        onClick={() => setActiveGame(game)}
                                        style={{ border: '1px solid #ddd' }}
                                    >
                                        Insights
                                    </Button>
                                </Card.Body>
                            </GameCard>
                        )
                    })}
                </GameContainer>
            }
        </div>
    )
}

export default Games