// Library imports
import React from 'react';
import { Button, ButtonGroup, Dropdown as BootStrapDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const filterLabels = {
    'Upcoming': 'upcoming',
    'Live': 'live',
    'Power Differences': 'power-rank',
    'Small Road Dogs': 'small-road-dogs',
    'Large Home Favorites': 'large-home-favs',
    'Road Favorites': 'road-favorites',
    'Division': 'division-game',
    'Conference': 'conference-game',
    'All': '0',
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
}


const Dropdown = ({ icon = <FontAwesomeIcon icon={faAngleDown} />, dropdownStyle = {}, toggleStyle = {}, buttonStyle = {}, value, setValue, menuItems = [], variant = 'light', caption = '', drop = 'down' }) => {
    return (
        <BootStrapDropdown
            as={ButtonGroup}
            style={dropdownStyle}
            onSelect={(key) => setValue(key)}
            drop={drop}
        >
            <Button
                variant={variant}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', whiteSpace: 'nowrap', ...buttonStyle }}
            >
                {value}
                {caption ? <span style={{ fontSize: 10 }}>{caption}</span> : <></>}
            </Button>
            <BootStrapDropdown.Toggle
                variant={variant}
                style={toggleStyle}
            >
                {icon}
            </BootStrapDropdown.Toggle>
            <BootStrapDropdown.Menu>
                {menuItems.map((item, i) => {
                    return (
                        <BootStrapDropdown.Item
                            key={i}
                            eventKey={filterLabels[item] ? filterLabels[item] : item}
                        >
                            {item}
                        </BootStrapDropdown.Item>
                    )
                })}
            </BootStrapDropdown.Menu>
        </BootStrapDropdown >
    )
}

export default Dropdown