// Library imports
import React, { useState, useEffect } from "react";
import { Row, Col, Nav } from 'react-bootstrap';
// Component imports
import CompareList from "../../../../CompareList";
import { Loader } from "../../../../Loader";
// Controller imports
import { keyFactorData } from "./KeyFactorController";
// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { addLoader, removeLoader } from "../../../../../store/slices/appSlice";
// Style imports
import { DetailSection, SectionTitle } from '../styles';

const Pitchers = ({ game, sport }) => {
    // Redux
    const { loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [tab, setTab] = useState(game.awayTeam.pitcher && game.homeTeam.pitcher ? 'pitcher' : 'pitching');
    const [dataFields, setDataFields] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(addLoader('factors'))
                const html = await keyFactorData(sport, game.awayTeam, game.homeTeam, tab);
                setDataFields(html);
            } catch (error) {
                console.log(`Error getting key factor objects for: ${sport}\n${error.message}`);
            } finally {
                dispatch(removeLoader('factors'))
            }
        }
        fetchData();
    }, [tab]);

    return (
        <DetailSection>
            <SectionTitle>
                Pitchers
            </SectionTitle>
            <Row>
                <Col>
                    <Nav variant="tabs" className='mb-2' defaultActiveKey={tab}>
                        <Nav.Item>
                            <Nav.Link onClick={(e) => setTab('pitcher')} eventKey='pitcher'>Starters</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={(e) => setTab('pitching')} eventKey='pitching'>Team</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            {loaders.find(loader => loader === 'factors') ?
                <Loader style={{ marginTop: '15px' }} />
                :
                <CompareList
                    labels={dataFields[2]}
                    dataSetOneTitle={tab === 'pitcher' ?
                        `${game.awayTeam.pitcher.firstName} ${game.awayTeam.pitcher.lastName}`
                        :
                        game.awayTeam.teamName
                    }
                    dataSetOneTitleColor={game.awayTeam.color}
                    dataSetOne={dataFields[0]}
                    dataSetTwoTitle={tab === 'pitcher' ?
                        `${game.homeTeam.pitcher.firstName} ${game.homeTeam.pitcher.lastName}`
                        :
                        game.homeTeam.teamName
                    }
                    dataSetTwoTitleColor={game.homeTeam.color}
                    dataSetTwo={dataFields[1]}
                />
            }
        </DetailSection>
    )
}

export default Pitchers