// React imports
import React, { useState } from "react";
import { ResponsivePie } from '@nivo/pie';
import { Row, Col, Card, Alert, Nav } from 'react-bootstrap';
// Style imports
import { DetailSection, SectionTitle } from '../styles';

const PublicMoneyPercent = ({ game, sport }) => {
    const [type, setType] = useState(sport === 'mlb' || sport === 'nhl' ? 'moneyline' : 'spread');
    return (
        <DetailSection>
            <SectionTitle>
                Public Betting
            </SectionTitle>
            <Row>
                <Col>
                    <Nav variant="tabs" className='mb-2' defaultActiveKey={type}>
                        <Nav.Item>
                            <Nav.Link onClick={(e) => setType('moneyline')} eventKey='moneyline'>Moneyline</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={(e) => setType('spread')} eventKey='spread'>Spread</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body style={{ height: '250px' }}>
                            <Card.Title className='card-title h4 pt-2 mb-0' style={{ backgroundColor: '#fff', fontWeight: 800 }}>
                                {`Ticket %`}
                            </Card.Title>
                            {(type === 'moneyline' && !game.awayTeam?.mlPercent && !game.homeTeam?.mlPercent)
                                || (type === 'spread' && !game.awayTeam?.spreadPercent && !game.homeTeam?.spreadPercent) ?
                                <Alert variant='primary' className='text-center mt-2'>No data reported.</Alert>
                                :
                                <ResponsivePie
                                    data={[
                                        {
                                            "id": game.homeTeam.abbr,
                                            "label": game.homeTeam.abbr,
                                            "value": type === 'moneyline' ? game.homeTeam.mlPercent : game.homeTeam.spreadPercent,
                                        },
                                        {
                                            "id": game.awayTeam.abbr,
                                            "label": game.awayTeam.abbr,
                                            "value": type === 'moneyline' ? game.awayTeam.mlPercent : game.awayTeam.spreadPercent
                                        },
                                    ]}
                                    colors={[game.homeTeam.color, game.awayTeam.color]}
                                    margin={{ top: 5, right: 2, bottom: 35, left: 2 }}
                                    padAngle={3}
                                    innerRadius={0.2}
                                    borderWidth={2}
                                    enableArcLinkLabels={false}
                                    arcLabel={e => e.id + " " + e.value + "%"}
                                    arcLabelsSkipAngle={10}
                                    arcLabelsTextColor="#ffffff"
                                />

                            }
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body style={{ height: '250px' }}>
                            <Card.Title className='card-title h4 pt-2  mb-0' style={{ backgroundColor: '#fff', fontWeight: 800 }}>
                                {`Money %`}
                            </Card.Title>
                            {(type === 'moneyline' && !game.awayTeam?.mlMoneyPercent && !game.homeTeam?.mlMoneyPercent)
                                || (type === 'spread' && !game.awayTeam?.spreadMoneyPercent && !game.homeTeam?.spreadMoneyPercent) ?
                                <Alert variant='primary' className='text-center mt-2'>No data reported.</Alert>
                                :
                                <ResponsivePie
                                    data={[
                                        {
                                            "id": game.homeTeam.abbr,
                                            "label": game.homeTeam.abbr,
                                            "value": type === 'moneyline' ? game.homeTeam.mlMoneyPercent : game.homeTeam.spreadMoneyPercent
                                        },
                                        {
                                            "id": game.awayTeam.abbr,
                                            "label": game.awayTeam.abbr,
                                            "value": type === 'moneyline' ? game.awayTeam.mlMoneyPercent : game.awayTeam.spreadMoneyPercent
                                        },
                                    ]}
                                    colors={[game.homeTeam.color, game.awayTeam.color]}
                                    margin={{ top: 5, right: 2, bottom: 35, left: 2 }}
                                    padAngle={3}
                                    innerRadius={0.2}
                                    borderWidth={2}
                                    enableArcLinkLabels={false}
                                    arcLabel={e => e.id + " " + e.value + "%"}
                                    arcLabelsSkipAngle={10}
                                    arcLinkLabelsDiagonalLength={1}
                                    arcLabelsTextColor="#ffffff"
                                />
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </DetailSection>
    )
}

export default PublicMoneyPercent