import { styled } from "styled-components";

export const TableHeader = styled.h1`
    font-size: 26px;
    display: flex;
    align-items: center;
`

export const TableSubHeader = styled.h2`
    font-size: 16px;
    line-height: 18px;
    color: #666;
`