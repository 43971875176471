// Library imports
import React, { useState } from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBlackboard, faCog, faList, faPlusCircle, faUserCircle } from "@fortawesome/free-solid-svg-icons";
// Component imports
import Picks from './Picks';
import Users from './Users';
// Style imports
import { DashboardWrapper, ProfileHeader, UserDisplayName, UserEmail, UserImage } from './styles';
// Redux imports
import { useSelector } from 'react-redux';
// Util imports
import { capitalize } from '../../utils/Global';
// Image imports
import userImage from '../../img/userPlaceholder.png';
import CreatePick from './CreatePick';
import Games from './Games';

const UserDashboard = () => {
    const { user } = useSelector(state => state.app);
    const [subpage, setSubpage] = useState('picks');

    return (
        <DashboardWrapper>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                <Navbar.Brand className="order-lg-0 mr-auto order-1 d-sm-block d-lg-none">
                    {capitalize(subpage)}
                </Navbar.Brand>
                <Navbar.Toggle className="mr-2" aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className='justify-content-center' >
                    <Nav
                        defaultActiveKey={subpage}
                        onSelect={(selectedKey) => setSubpage(selectedKey)}
                    >
                        <Nav.Link style={{ marginRight: '10px' }} eventKey="picks">
                            <FontAwesomeIcon style={{ marginRight: '3px', marginTop: '1px' }} icon={faList} /> Picks
                        </Nav.Link>
                        <Nav.Link style={{ marginRight: '10px' }} eventKey="games">
                            <FontAwesomeIcon style={{ marginRight: '3px', marginTop: '1px' }} icon={faBlackboard} /> Games
                        </Nav.Link>
                        {user.admin ?
                            <Nav.Link style={{ marginRight: '10px' }} eventKey='create-pick'>
                                <FontAwesomeIcon style={{ marginRight: '3px', marginTop: '1px' }} icon={faPlusCircle} /> Create Pick
                            </Nav.Link>
                            :
                            <></>
                        }
                        {user.admin ?
                            <Nav.Link style={{ marginRight: '10px' }} eventKey='users'>
                                <FontAwesomeIcon style={{ marginRight: '3px', marginTop: '1px' }} icon={faUserCircle} /> Users
                            </Nav.Link>
                            :
                            <></>
                        }
                        <Nav.Link style={{ marginRight: '10px' }} eventKey="settings">
                            <FontAwesomeIcon style={{ marginRight: '3px', marginTop: '1px' }} icon={faCog} /> Settings
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Container style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={2} xl={2}>
                        <ProfileHeader>
                            <UserImage
                                src={userImage}
                            />
                            <UserDisplayName>
                                {user.displayName}
                            </UserDisplayName>
                            <UserEmail>
                                {user.email}
                            </UserEmail>
                        </ProfileHeader>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={10} xl={10}>
                        {subpage === 'picks' ?
                            <Picks />
                            :
                            subpage === 'users' ?
                                <Users />
                                :
                                subpage === 'create-pick' ?
                                    <CreatePick />
                                    :
                                    subpage === 'games' ?
                                        <Games />
                                        :
                                        null}
                    </Col>
                </Row>
            </Container>
        </DashboardWrapper>
    )
}

export default UserDashboard;