import { styled } from "styled-components";

export const DetailSection = styled.div`
    position: relative;
    padding-bottom: 25px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
`

export const SectionTitle = styled.div`
    display: flex;
    font-size: 26px;
    margin-bottom: 15px;
`
export const SectionSubTitle = styled.span`
    font-size: 18px;
    line-height: 18px;
`
export const TeamName = styled.span`
    color: ${props => props.color}
`

export const CloseButton = styled.div`
    position: absolute;
    right: 0;
    top: 5px;
    button {
        background: #f8d7da;
        font-size: 14px; 
        line-height: 14px;
        color: rgb(220, 53, 69); 
        padding: 2px 4px;
        border-radius: 99px;
    }
`