export const addPlusSign = (value) => {
    if (value > 0) {
        value = String(value).replace('+', '');
        return `+${value}`
    }
    return value;
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    const wordArray = s.split(" ");
    var finalString = "";
    wordArray.forEach(word => {
        finalString = finalString + word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
    });
    finalString = finalString.trim();
    return finalString;
}

export const calcRecord = (picks = []) => {
    var units = 0;
    const winArr = [];
    const lossArr = [];
    picks.forEach(pick => {
        var juice = 0;
        if (pick.type === 'teaser') {
            juice = -110;
        }
        if (pick.type === 'straight') {
            const play = pick.plays[0];
            juice = play.type === 'moneyline' ?
                play.odds
                :
                play.juice ?
                    play.juice
                    :
                    -110;
        }
        if (pick.type === 'parlay') {
            var multiplier = 1;
            pick.plays.forEach((play, i) => {
                let playJuice = play.type === 'moneyline' ?
                    play.odds
                    :
                    play.juice ?
                        play.juice
                        :
                        -110;
                let decimalOdd = 0
                if (playJuice < 0) {
                    decimalOdd = (100 / (playJuice * -1)) + 1;
                } else {
                    decimalOdd = (playJuice / 100) + 1;
                }
                multiplier = multiplier * decimalOdd;
            })
            if (multiplier >= 2) {
                juice = (multiplier - 1) * 100;
            } else {
                juice = -100 / (multiplier - 1)
            }
        }
        if (pick.status === 'win') {
            units = units + pick.units;
        }
        if (pick.status === 'loss') {
            units = units + (juice > 0 ?
                Math.abs((juice / 100) * pick.units)
                :
                Math.abs((-100 / juice) * pick.units))
            units = units - pick.units;
        }
    })

    return `${units.toFixed(2)}u (${winArr.length}-${lossArr.length})`
}