import { styled } from "styled-components";
import { PRIMARY_COLOR } from "../../config/theme";

export const DashboardWrapper = styled.div`
    flex-grow: 1;
`

export const ProfileHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
`

export const UserDisplayName = styled.span`
    font-size: 20px;
    color: ${PRIMARY_COLOR};
    font-weight: 800;
`

export const UserEmail = styled.span`
    font-size: 14px;
`

export const UserImage = styled.img`
    margin-bottom: 15px;
    height: 100px;
    width: auto;
`

export const ProductsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 15px;
`