import { styled } from "styled-components";

export const BlogContainer = styled.div`
    display: flex;
`
export const BlogHeader = styled.div`
    display: flex;
    padding-bottom: 25px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
`