// React imports
import React, { useState } from "react";
import { Row, Col, Card, Nav, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faEquals } from "@fortawesome/free-solid-svg-icons";
// Util imports
import { addPlusSign } from '../../../../../utils/Global';
// Style imports
import { DetailSection, SectionTitle } from '../styles';

const LineData = ({ game, sport }) => {
    const [type, setType] = useState('moneyline');
    const [bookType, setBookType] = useState('moneylines');

    const createLineDataJSX = (team) => {
        return (
            <Card>
                <Card.Body>
                    <Card.Title className='card-title h4 pt-2 pb-2 mb-0' style={{ color: team.color, backgroundColor: '#fff', fontWeight: 800 }}>
                        {team.teamName}
                    </Card.Title>
                    <ListGroup className='labelValueListGroup justify-content-row' variant="flush">
                        <ListGroup.Item className='pl-0'>
                            <label>Opening</label>
                            <span>
                                {type === 'moneyline' ?
                                    addPlusSign(team.openMoneyLine)
                                    :
                                    addPlusSign(team.moneyLine < 0 ? game.openingSpread * -1 : game.openingSpread)
                                }
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item className='pl-0'>
                            <label>Current</label>
                            <span>
                                {type === 'moneyline' ?
                                    addPlusSign(team.moneyLine)
                                    :
                                    addPlusSign(team.spread)
                                }
                                {type === 'moneyline' ?
                                    <FontAwesomeIcon
                                        style={{ marginLeft: 4 }}
                                        icon={team.moneyLine > team.openMoneyLine ? faArrowUp : team.moneyLine < team.openMoneyLine ? faArrowDown : faEquals}
                                        color={team.moneyLine > team.openMoneyLine ? 'green' : team.moneyLine < team.openMoneyLine ? 'red' : '#444'}
                                    />
                                    :
                                    <FontAwesomeIcon
                                        style={{ marginLeft: 4 }}
                                        icon={team.spread > game.openingSpread ? faArrowUp : team.spread < game.openingSpread ? faArrowDown : faEquals}
                                        color={team.spread > game.openingSpread ? 'green' : team.spread < game.openingSpread ? 'red' : '#444'}
                                    />
                                }
                            </span>
                        </ListGroup.Item>
                        {type === 'moneyline' ?
                            <ListGroup.Item className='pl-0'>
                                <label>Average</label>
                                {team.averageMoneylines ?
                                    <span>
                                        {team.moneyLine > 0 ? `+${team.averageMoneylines.dog.avgML.toFixed(0)}` : team.averageMoneylines.favorite.avgML.toFixed(0)}
                                    </span>
                                    :
                                    <span>
                                        N/A
                                    </span>
                                }
                            </ListGroup.Item>
                            :
                            <></>
                        }
                    </ListGroup>
                </Card.Body>
            </Card>
        )
    }

    return (
        <DetailSection>
            <SectionTitle>
                Line Movement
            </SectionTitle>
            <Row>
                <Col>
                    <Nav variant="tabs" className='mb-2' defaultActiveKey={type}>
                        <Nav.Item>
                            <Nav.Link onClick={(e) => setType('moneyline')} eventKey='moneyline'>Moneyline</Nav.Link>
                        </Nav.Item>
                        {sport === 'mlb' || sport === 'nhl' ?
                            <></>
                            :
                            <Nav.Item>
                                <Nav.Link onClick={(e) => setType('spread')} eventKey='spread'>Spread</Nav.Link>
                            </Nav.Item>
                        }
                        <Nav.Item>
                            <Nav.Link onClick={(e) => setType('books')} eventKey='books'>Books</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Row>
                {type === 'moneyline' || type === 'spread' ?
                    <>
                        <Col>
                            {createLineDataJSX(game.awayTeam)}
                        </Col>
                        <Col>
                            {createLineDataJSX(game.homeTeam)}
                        </Col>
                    </>
                    :
                    <>
                        <Col>
                            <Card>
                                <Card.Title className='text-center card-title pt-2 pb-2 mb-0' style={{ color: game.awayTeam.color }}>
                                    {game.awayTeam.teamName}
                                </Card.Title>
                                <ListGroup className='labelValueListGroup justify-content-row' variant="flush">
                                    {game.bookData[bookType] ?
                                        game.bookData[bookType].map((book, i) => {
                                            return (
                                                <ListGroup.Item
                                                    key={`${book.label} ${i}`}
                                                    style={{
                                                        backgroundColor: book.awayLine === Math.min(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.awayLine)) ? '#f8d7da' : book.awayLine === Math.max(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.awayLine)) ? '#d4edda' : 'transparent'
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            fontSize: '14px',
                                                            color: book.awayLine === Math.min(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.awayLine)) ? '#721c24' : book.awayLine === Math.max(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.awayLine)) ? '#155724' : book.color,
                                                            fontWeight: '800'
                                                        }}
                                                    >
                                                        {book.label}
                                                    </label>
                                                    <span style={{
                                                        fontSize: '14px',
                                                        color: book.awayLine === Math.min(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.awayLine)) ? '#721c24' : book.awayLine === Math.max(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.awayLine)) ? '#155724' : '#000',
                                                        fontWeight: '800'
                                                    }}>
                                                        {addPlusSign(book.awayLine)}
                                                    </span>
                                                </ListGroup.Item>
                                            )
                                        })
                                        :
                                        <></>
                                    }
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Title className='text-center card-title pt-2 pb-2 mb-0' style={{ color: game.awayTeam.color }}>
                                    {game.homeTeam.teamName}
                                </Card.Title>
                                <ListGroup className='labelValueListGroup justify-content-row' variant="flush">
                                    {game.bookData[bookType] ?
                                        game.bookData[bookType].map((book, i) => {
                                            return (
                                                <ListGroup.Item
                                                    key={`${book.label} ${i}`}
                                                    style={{
                                                        backgroundColor: book.homeLine === Math.min(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.homeLine)) ? '#f8d7da' : book.homeLine === Math.max(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.homeLine)) ? '#d4edda' : 'transparent'
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            fontSize: '14px',
                                                            color: book.homeLine === Math.min(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.homeLine)) ? '#721c24' : book.homeLine === Math.max(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.homeLine)) ? '#155724' : book.color,
                                                            fontWeight: '800'
                                                        }}
                                                    >
                                                        {book.label}
                                                    </label>
                                                    <span style={{
                                                        fontSize: '14px',
                                                        color: book.homeLine === Math.min(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.homeLine)) ? '#721c24' : book.homeLine === Math.max(...game.bookData[`${bookType.toLowerCase()}`].map(book => book.homeLine)) ? '#155724' : '#000',
                                                        fontWeight: '800'
                                                    }}>
                                                        {addPlusSign(book.homeLine)}
                                                    </span>
                                                </ListGroup.Item>
                                            )
                                        })
                                        :
                                        <></>
                                    }
                                </ListGroup>
                            </Card>
                        </Col>
                    </>
                }
            </Row>
        </DetailSection>
    )
}

export default LineData