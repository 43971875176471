export const makePayment = async ({ payment_intent_id, paymentId, product, customer }, cart) => {
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/stripe/payment`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ payment_intent_id, paymentId, product, customer, cart })
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};