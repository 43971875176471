// Library imports
import React from 'react';
import { Col, Container, Row, ListGroup } from 'react-bootstrap';
import Faq from "react-faq-component";
// Style imports
import { ButtonContainer, HeroImage, HeroMainText, HeroSubText, HeroTopLine, HeroWrapper, ImageColumn } from './styles';

const HeroSection = ({ variant, topLine, mainText, subText = '', list = [], image, imageSide, buttons, faq = false }) => {
    return (
        <HeroWrapper variant={variant}>
            <Container>
                <Row style={{
                    display: 'flex',
                    flexDirection: imageSide === 'left' ? 'row' : 'row-reverse',
                    alignItems: 'center'
                }}>
                    <Col xs={12} sm={12} md={12} lg={6}>
                        <HeroTopLine>
                            {topLine}
                        </HeroTopLine>
                        <HeroMainText variant={variant}>
                            {mainText}
                        </HeroMainText>
                        {list.length ?
                            <ListGroup as="ul" variant="flush">
                                {list.map((item, i) => {
                                    return (
                                        <ListGroup.Item variant="light" style={{ marginTop: '5px', background: '#f5f5f5' }} as="li" key={i}>{item}</ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                            :
                            <HeroSubText variant={variant}>
                                {subText}
                            </HeroSubText>
                        }
                        <ButtonContainer>
                            {buttons.map((button, i) => {
                                return (button)
                            })}
                        </ButtonContainer>
                    </Col>
                    <ImageColumn imageSide={imageSide} xs={12} sm={12} md={12} lg={6}>
                        {faq === true ?
                            <Faq
                                data={{
                                    rows: [
                                        {
                                            title: "What kind of data does the platform provide?",
                                            content: `Our comprehensive insights provide a 360 degree view of each game on the board.  Our game cards provide key factor analysis, line movement data, situational data, top headlines, AI predictions, and so much more.  On top of that you will also have access to our unique trend calculator that can be applied to the daily board.  All the information you need to quickly and easily analyze each game is provided on both our web and mobile apps.`,
                                        },
                                        {
                                            title: "Is this just a capping service?",
                                            content: `While every package includes hand selected picks from our experts, access to the platform provides so much more.  Dive into each game with our matchup insights, unique trends, and community discussions.  You will also be invited to join our private Discord where our experts and community are there to answer all of your questions.`,
                                        },
                                        {
                                            title: "How do you track VIP results?",
                                            content: `Once you log in you will have access to our one of a kind VIP insights panel.  This includes a look into our daily, weekly, monthly, and all time play results.  Our custom filters allow you to take an in-depth look at how our system performs across many different parameters.  Filter by sport, unit size, time of year, and so much more.`,
                                        },
                                        {
                                            title: "What comes with an access package?",
                                            content: "With an Access Package you will get all of the benefits our platform has to offer.  With your account you will have access to both our web and mobile apps.",
                                        },
                                        {
                                            title: "How are picks delivered?",
                                            content: "Expert picks can be found via our web and mobile apps with an access package.  All plays are automatically tweeted out to our VIP Twitter page via our bots right as they are entered by one of our experts.  With a pick package plays will be delivered to your email each day.",
                                        },
                                    ],
                                }}
                                styles={{
                                    bgColor: 'transparent',
                                    titleTextColor: "#000",
                                    rowTitleColor: "#000",
                                    padding: '60px 0px',
                                    rowContentColor: '#666',
                                }}
                            />
                            :
                            <HeroImage
                                src={image}
                            />
                        }
                    </ImageColumn>
                </Row>
            </Container>
        </HeroWrapper>
    )
}

export default HeroSection