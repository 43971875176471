// Library imports
import React, { useEffect } from 'react';
// Component imports
import Header from '../components/Header';
import Footer from '../components/Footer';
import UserDashboard from '../components/UserDashboard';
import { Loader } from '../components/Loader';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../store/slices/appSlice';
// Firebase imports
import firebase from 'firebase';

const App = () => {
    const { user } = useSelector(state => state.app);
    const dispatch = useDispatch();

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                console.log('NO USER')
                window.location = '/login'
            } else {
                console.log('FETCHING USER DATA')
                dispatch(fetchUser());
            }
        })
    }, []);

    return (
        <div className='page-wrapper'>
            <Header
                currentPage={'app'}
            />
            {!user ?
                <div style={{ minHeight: '80vh' }}>
                    <Loader loading={true} />
                </div>
                :
                <UserDashboard />
            }
            <Footer />
        </div>
    )
}

export default App